import React from 'react';
import { Button } from '@blueprintjs/core';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import moment from 'moment';

import styles from './DetailsCard.module.scss';
import { CellRenderer } from '../../../components/MegaGrid/MegaGrid';

type DetailsCardProps<T> = {
  title: string;
  data: T;
  keyMap: {
    [index: string]: string | number;
  };
  onClose(): void;
  onNextClick(rowData: T): void;
  onPreviousClick(rowData: T): void;
  cellRenderer?: CellRenderer;
};

const DetailsCard = <T extends { [index: string]: any }>(
  props: DetailsCardProps<T>
): React.ReactElement => {
  const {
    title,
    data,
    keyMap,
    onClose,
    onNextClick,
    onPreviousClick,
    cellRenderer
  } = props;
  const timestamp =
    data.CreatedAt &&
    data.CreatedAt.seconds &&
    new Date(parseInt(data.CreatedAt.seconds, 10) * 1000);

  return (
    <div className={styles['details-card']}>
      <div className={styles.header}>
        <Button
          minimal
          icon="chevron-left"
          intent="primary"
          onClick={(): void => onPreviousClick(data)}
        >
          Previous
        </Button>
        <div className={styles.title}>{title}</div>
        <Button
          minimal
          rightIcon="chevron-right"
          intent="primary"
          onClick={(): void => onNextClick(data)}
        >
          Next
        </Button>
      </div>
      <div className={styles.details}>
        {Object.keys(keyMap).map(key => {
          return (
            <div key={key} className={styles.row}>
              <div className={styles.key}>{keyMap[key]}</div>
              <div className={styles.value}>
                {cellRenderer && cellRenderer[key]
                  ? cellRenderer[key](get(data, key)) || '-'
                  : get(data, key) || '-'}
              </div>
            </div>
          );
        })}
      </div>
      <div className={styles.footer}>
        <div className={styles.timestamp}>
          {timestamp && (
            <>
              <div className={styles.date}>
                {moment(timestamp).format('dddd, MMMM Do, YYYY')}
              </div>
              <div className={styles.time}>
                {moment(timestamp).format('hh:mm A')}
              </div>
            </>
          )}
        </div>
        <Button minimal icon="cross" intent="primary" onClick={onClose}>
          Close
        </Button>
      </div>
    </div>
  );
};

DetailsCard.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  keyMap: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  onClose: PropTypes.func.isRequired,
  onNextClick: PropTypes.func.isRequired,
  onPreviousClick: PropTypes.func.isRequired
};

export default DetailsCard;
