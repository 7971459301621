import React, { useEffect, KeyboardEvent, ChangeEvent } from 'react';
import { InputGroup, Intent, Icon } from '@blueprintjs/core';
import { Switch, Route } from 'react-router-dom';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { createSelector } from 'reselect';

import styles from './Kyc.module.scss';

import MegaButtonGroup, {
  RouteType
} from '../../../components/MegaButtonGroup/MegaButtonGroup';
import Pending from './Pending';
import Accepted from './Accepcted';
import Rejected from './Rejected';
import { AppState } from '../../../reducers';
import Loading from '../../../components/Loading/Loading';
import PlayerNotFoundToaster from './PlayerNotFoundToaster';
import { resetProfile } from '../../../reducers/watson/player/actions';
import {
  syncPendingKycOrders,
  cancelPendingKycOrdersSync,
  syncAcceptedKycOrders,
  cancelAcceptedKycOrdersSync,
  syncRejectedKycOrders,
  cancelRejectedKycOrdersSync
} from '../../../reducers/watson/kyc/actions';
import {
  WATSON_KYC_SEARCH_PLAYERS_ORDERS,
  WATSON_KYC_RESET_METADATA
} from '../../../reducers/watson/kyc/ActionTypes';

type KycProps = {
  path: string;
};

const selectProfile = createSelector(
  (state: AppState) => state.watson.player.profile,
  profile => profile
);
const selectKycMetadata = createSelector(
  (state: AppState) => state.watson.kyc.metadata,
  metadata => metadata
);
const selectPendingCount = createSelector(
  (state: AppState) => state.watson.kyc,
  ({ orders, metadata }) =>
    Object.keys(orders).reduce((count: number, kycOrderId) => {
      return (
        count +
        (orders[kycOrderId].State === 'Manual_Supervision' &&
        (metadata.searchedPlayerId
          ? metadata.searchedPlayerId === orders[kycOrderId].PlayerId
          : true)
          ? 1
          : 0)
      );
    }, 0)
);

const Kyc: React.FC<KycProps> = props => {
  const { path } = props;
  const dispatch = useDispatch();
  const metadata = useSelector(selectKycMetadata, shallowEqual);
  const pendingCount = useSelector(selectPendingCount, shallowEqual);
  const { searchingPlayer, notFound, notFoundMessage } = useSelector(
    selectProfile,
    shallowEqual
  );

  const routes: RouteType[] = [
    {
      title: 'Verification Pending',
      path: `${path}/pending`,
      // icon: 'dot',
      tag: `${pendingCount} new`,
      component: Pending
    },
    {
      title: 'Accepted',
      path: `${path}/accepted`,
      icon: 'tick-circle',
      component: Accepted
    },
    {
      title: 'Rejected',
      path: `${path}/rejected`,
      icon: 'error',
      component: Rejected
    }
  ];

  const handlePlayerIdSearch = (e: KeyboardEvent<HTMLInputElement>): void => {
    const playerId = e.currentTarget.value;

    if (e.key === 'Enter' && playerId) {
      dispatch({
        type: WATSON_KYC_SEARCH_PLAYERS_ORDERS,
        payload: { playerId }
      });
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const playerId = e.currentTarget.value;

    if (playerId === '') {
      dispatch({
        type: WATSON_KYC_RESET_METADATA,
        payload: {}
      });
    }
  };

  useEffect(() => {
    if (notFound) {
      PlayerNotFoundToaster.show({
        message: notFoundMessage,
        intent: Intent.DANGER
      });

      dispatch(resetProfile());
    }
  }, [dispatch, notFound, notFoundMessage]);

  // start pending kyc orders sync
  useEffect(() => {
    dispatch(syncPendingKycOrders());
    dispatch(syncAcceptedKycOrders());
    dispatch(syncRejectedKycOrders());

    return (): void => {
      dispatch(cancelPendingKycOrdersSync());
      dispatch(cancelAcceptedKycOrdersSync());
      dispatch(cancelRejectedKycOrdersSync());
    };
  }, [dispatch]);

  return (
    <div id={styles.kyc}>
      <div className={styles['kyc-header']}>
        <div className={styles.title}>KYC Details</div>
        <InputGroup
          className={styles['search-input']}
          placeholder="Search using player id"
          type="search"
          leftIcon="search"
          large
          onKeyDown={handlePlayerIdSearch}
          onChange={handleChange}
          defaultValue={metadata.searchedPlayerId}
        />
      </div>
      <div className={styles['btn-group']}>
        <div style={{ paddingTop: '40px', width: '730px' }}>
          <MegaButtonGroup routes={routes} />
        </div>
      </div>
      {metadata.searchedPlayerId && (
        <div
          style={{
            textAlign: 'center',
            padding: '16px 0',
            color: 'rgb(0, 189, 170)'
          }}
        >
          <Icon icon="info-sign" style={{ marginRight: '4px' }} />
          Results are filtered for player id&nbsp;
          <strong>{metadata.searchedPlayerId}</strong>
          .&nbsp;Clear search input field to show all results.
        </div>
      )}
      <div className={styles.grid}>
        <Switch>
          {routes.map(route => {
            return (
              <Route
                key={route.path}
                exact
                path={route.path}
                component={route.component}
              />
            );
          })}
        </Switch>
      </div>

      {searchingPlayer && <Loading message="fetching player details" />}
    </div>
  );
};

export default React.memo(Kyc);
