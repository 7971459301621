import dummyFlaggedPlayers from './dummy-flagged-players.json';
import dummyBlockedPlayers from './dummy-blocked-players.json';
import { Player } from '../../../reducers/watson/blocked-and-flagged-players/types';

export const getFlaggedPlayers = async (): Promise<Player[]> =>
  new Promise(resolve => {
    setTimeout(() => {
      resolve(dummyFlaggedPlayers);
    }, 3000);
  });

export const getBlockedPlayers = async (): Promise<Player[]> =>
  new Promise(resolve => {
    setTimeout(() => {
      resolve(dummyBlockedPlayers);
    }, 3000);
  });
