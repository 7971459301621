import React, { useEffect } from 'react';
import { HTMLTable, Button } from '@blueprintjs/core';
import PropTypes from 'prop-types';
import equal from 'deep-equal';
import get from 'lodash/get';

import styles from './MegaGrid.module.scss';

export interface CellRenderer {
  // render a particular cell
  // using function provided
  [index: string]: (s: string) => React.ReactElement | null;
}

interface MegaGridProps<T> {
  // data to be rendered
  data: T[];
  // action to perform on row click
  onRowClick?(e: React.MouseEvent<HTMLElement>, selectedRow: T): void;
  // render keys provided in keyMap
  // using value as column label
  keyMap: {
    [index: string]: string;
  };
  // key name which will be unique for each data row
  uniqueId?: string;
  // group data based on this key
  group?: string;
  selectedRow?: T;
  cellRenderer?: CellRenderer;
}

type GroupedData<T> = {
  [index: string]: T[];
};

const groupData = <T extends { [index: string]: string | number }>(
  data: T[],
  groupKey: string
): GroupedData<T> => {
  const groupedData: GroupedData<T> = {};

  data.forEach((d: T) => {
    groupedData[d[groupKey]] = groupedData[d[groupKey]] || [];
    groupedData[d[groupKey]].push(d);
  });

  return groupedData;
};

const MegaGrid = <T extends { [index: string]: string | number }>(
  props: MegaGridProps<T>
): React.ReactElement => {
  const {
    data,
    onRowClick,
    keyMap,
    uniqueId,
    group,
    selectedRow,
    cellRenderer
  } = props;
  const keys = Object.keys(keyMap);

  let groupedData: GroupedData<T> | undefined;
  if (group) {
    groupedData = groupData(data, group);
  }

  const renderGroup = (data2: T[]): React.ReactFragment => {
    return data2.map((rowData: T, i: number) => {
      return (
        <tr
          id={uniqueId && `id-${rowData[uniqueId].toString()}`}
          key={(uniqueId && rowData[uniqueId]) || i}
          onClick={(e): void => {
            onRowClick && onRowClick(e, rowData);
          }}
          className={
            equal(rowData, selectedRow) ? styles.selected : styles.unselected
          }
        >
          {keys.map((key: string) => {
            return (
              <td key={key} className={[styles.cell, key].join(' ')}>
                {cellRenderer && cellRenderer[key]
                  ? cellRenderer[key](get(rowData, key) as string)
                  : get(rowData, key)}
              </td>
            );
          })}
        </tr>
      );
    });
  };

  useEffect(() => {
    if (selectedRow && uniqueId) {
      const selectedTR = document.getElementById(`id-${selectedRow[uniqueId]}`);
      selectedTR &&
        selectedTR.scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        });
    }
  }, [selectedRow, uniqueId]);

  return (
    <div className={styles['mega-grid']}>
      <div className={styles['mega-filter']}>
        <Button
          minimal
          intent="primary"
          icon="filter"
          className={styles['filter-btn']}
          disabled
          title="coming soon"
        >
          Filter
        </Button>
      </div>
      <HTMLTable interactive className={styles['mega-grid-table']}>
        <thead>
          <tr style={{ background: '#F0F0F0' }}>
            {keys.map((key: string) => {
              return (
                <th key={key} style={{ color: '#6D7278', fontSize: '0.75rem' }}>
                  {keyMap[key]}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {groupedData
            ? Object.keys(groupedData).map((groupKey: string) => {
                return [
                  // render tr with groupKey
                  <tr key={`group-${groupKey}`} className={styles['group-tr']}>
                    <th
                      style={{ color: '#00BDAA', fontWeight: 'bold' }}
                      colSpan={Object.keys(keyMap).length}
                    >
                      {groupKey}
                    </th>
                  </tr>,
                  // render data for groupKey
                  groupedData && renderGroup(groupedData[groupKey])
                ];
              })
            : renderGroup(data)}

          {data.length === 0 && (
            <tr>
              <th
                className={styles['no-data']}
                colSpan={Object.keys(keyMap).length}
              >
                No data available
              </th>
            </tr>
          )}
        </tbody>
      </HTMLTable>
    </div>
  );
};

MegaGrid.defaultProps = {
  onRowClick: undefined,
  group: undefined
};

MegaGrid.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onRowClick: PropTypes.func,
  keyMap: PropTypes.shape({}).isRequired,
  group: PropTypes.string
};

export default MegaGrid;
