import React, { useMemo, useEffect, useState } from 'react';
import firebase from 'firebase/app';
import moment from 'moment';
// import { useSelector } from 'react-redux';

import './Deposits.module.scss';

import useSyncOrders from '../../../hooks/useSyncOrders';
// import useDetectScrollBottom from '../../../../hooks/useDetectScrollBottom';
// import useFetchMoreOrders from '../../../hooks/useFetchMoreOrders';
import useSelectSearchedOrder from '../../../hooks/useSelectSearchedOrder';
import useInitializeOrdersComponent from '../../../hooks/useInitializeOrdersComponent';
import PlayerOrderView from '../PlayerOrdersView';
// import { AppState } from '../../../../reducers';

const Deposits: React.FC = () => {
  const detailsKeyMap = {
    Title: 'TITLE',
    id: 'ORDER ID',
    'Fulfillments[0].Payment.Amount': 'AMOUNT',
    // 'Fulfillments[0].Payment.Currency': 'CURRENCY',
    State: 'STATE',
    'Payments[0].Source': 'SOURCE',
    'Payments[0].Destination': 'DESTINATION',
    'Payments[0].PG': 'PAYMENT GATEWAY',
    'Payments[0].PgTxnID': 'TRANSACTION ID'
  };
  const category = 'Deposit';
  // const { isFetchingOrders: ifo } = useSelector(
  //   (state: AppState) => state.watson.player.metadata
  // );

  const {
    playerId,
    selectedRow,
    selectRow,
    setSelectedRow,
    onPreviousClick,
    onNextClick,
    // isLastPage,
    // data,
    orders,
    metadata
  } = useInitializeOrdersComponent({
    category
  });

  const depositData = Object.values(orders)
    .filter(
      order =>
        (order.Category === 'Purchase' && order.Product.Type === 'GEM_PACK') ||
        order.Category === 'Deposit'
    )
    .sort((o1, o2) => (o1.CreatedAt.seconds > o2.CreatedAt.seconds ? -1 : 1));

  useSelectSearchedOrder({
    category,
    setSelectedRow,
    metadata,
    orders
  });

  const query = useMemo(
    () =>
      firebase
        .firestore()
        .collection('central_orders')
        .where('PlayerId', '==', playerId)
        .where('Category', '==', 'Purchase')
        .where('Product.Type', '==', 'GEM_PACK')
        .where('State', 'in', ['Complete', 'Failed', 'Processing', 'Pending'])
        .orderBy('CreatedAt', 'desc'),
    [playerId]
  );

  const [startDate, setStartDate] = useState(new Date());
  useEffect(() => {
    setStartDate(
      new Date(
        moment()
          .endOf('day')
          .subtract(400, 'days')
          .format()
      )
    );
  }, []);

  const query2 = useMemo(
    () =>
      firebase
        .firestore()
        .collection('central_orders')
        .where('PlayerId', '==', playerId)
        .where('Category', '==', category)
        .where('State', 'in', ['Complete', 'Failed', 'Processing', 'Pending'])
        .orderBy('CreatedAt', 'desc'),
    [playerId, category]
  );

  useSyncOrders({ playerId, query, startDate });
  useSyncOrders({ playerId, query: query2, startDate });

  // const isAtBottom = useDetectScrollBottom();
  // useFetchMoreOrders({
  //   isAtBottom,
  //   isLastPage,
  //   playerId,
  //   query,
  //   startDate,
  //   category
  // });
  // useFetchMoreOrders({
  //   isAtBottom,
  //   isLastPage,
  //   playerId,
  //   query: query2,
  //   startDate,
  //   category: 'Purchase'
  // });
  // // const isFetchingOrders = isFetchingOrders1 && isFetchingOrders2;
  // const isFetchingOrders = !!(ifo.Deposit || ifo.Purchase);

  return (
    <PlayerOrderView
      detailsTitle="DEPOSITS"
      data={depositData}
      keyMap={{ formattedTime: 'TIME', ...detailsKeyMap }}
      detailsKeymap={detailsKeyMap}
      isLastPage
      isFetchingOrders={false}
      selectedRow={selectedRow}
      selectRow={selectRow}
      setSelectedRow={setSelectedRow}
      onNextClick={onNextClick}
      onPreviousClick={onPreviousClick}
    />
  );
};

export default Deposits;
