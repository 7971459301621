import { call } from 'redux-saga/effects';
import { DocumentData } from '@firebase/firestore-types';

import rsf from '../../../firestore';
import { Profile } from '../../../reducers/watson/player/profile.types';

export default function* getPublicProfile(
  playerId: string
): Generator<object, Profile, DocumentData> {
  const snapshot: DocumentData = yield call(
    rsf.firestore.getDocument,
    // `players/${playerId}/private/profile`
    `players/${playerId}`
  );

  return {
    ...snapshot.data()
    // PlayerId: playerId
  };
}
