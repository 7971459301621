import { useState } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '../../reducers';
import {
  OrderCategories,
  Order,
  OrdersMap
} from '../../reducers/watson/player/orders.types';
import { findPreviousIndex, findNextIndex } from '../../lib/array';
import { Metadata } from '../../reducers/watson/player/metadata.types';

export interface UseInitializeOrdersComponentProps {
  category: OrderCategories;
  ordersFilter?(o: Order): boolean;
}

export interface UseInitializeOrdersComponentRet {
  playerId: string;
  selectedRow: Order | undefined;
  selectRow(e: React.MouseEvent, sr: Order): void;
  setSelectedRow(sr: Order | undefined): void;
  onPreviousClick(rowData: Order): void;
  onNextClick(rowData: Order): void;
  isLastPage: boolean;
  data: Order[];
  orders: OrdersMap;
  metadata: Metadata;
}

const useInitializeOrdersComponent = (
  props: UseInitializeOrdersComponentProps
): UseInitializeOrdersComponentRet => {
  const { category, ordersFilter: filter } = props;

  const { orders, metadata, profile } = useSelector(
    (state: AppState) => state.watson.player
  );
  const playerId = profile.PlayerId;
  const data = Object.values(orders).filter(order => {
    if (filter) return filter(order);
    return order.Category === category;
  });
  const isLastPage = metadata.isLastPage[category];

  const [selectedRow, setSelectedRow] = useState<Order | undefined>();

  const selectRow = (e: React.MouseEvent, sr: Order): void => {
    setSelectedRow(sr);
  };

  const onPreviousClick = (rowData: Order): void => {
    setSelectedRow(data[findPreviousIndex(data, rowData)]);
  };

  const onNextClick = (rowData: Order): void => {
    setSelectedRow(data[findNextIndex(data, rowData)]);
  };

  return {
    playerId,
    selectedRow,
    selectRow,
    setSelectedRow,
    onPreviousClick,
    onNextClick,
    isLastPage,
    data,
    orders,
    metadata
  };
};

export default useInitializeOrdersComponent;
