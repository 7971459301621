import React from 'react';
import {
  NavbarGroup,
  Alignment,
  Popover,
  PopoverPosition,
  Menu,
  MenuItem,
  Icon
} from '@blueprintjs/core';
import { useDispatch } from 'react-redux';

import styles from './MegaNavbar.module.scss';
import { logoutRequest } from '../../reducers/auth/actions';

interface RightMenuProps {
  email: string | undefined;
  photoURL: string | undefined;
}

const RightMenu: React.FC<RightMenuProps> = props => {
  const { email, photoURL } = props;
  const dispatch = useDispatch();

  return (
    <>
      {email && (
        <NavbarGroup align={Alignment.RIGHT} className={styles.switch}>
          <Popover
            position={PopoverPosition.BOTTOM_RIGHT}
            modifiers={{
              arrow: { enabled: false }
            }}
          >
            <div className={styles['user-email']}>
              <img src={photoURL} alt="" className={styles['user-dp']} />
              {email}
              <Icon icon="caret-down" color="#7045AF" />
            </div>
            <Menu>
              <MenuItem
                text="Logout"
                icon="log-out"
                onClick={(): void => {
                  dispatch(logoutRequest());
                }}
              />
            </Menu>
          </Popover>
          <div>
            Switch
            <Icon icon="caret-down" color="#7045AF" />
          </div>
        </NavbarGroup>
      )}
    </>
  );
};

export default React.memo(RightMenu);
