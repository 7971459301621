import React, { KeyboardEvent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';

import styles from './Login.module.scss';

import googleLogo from '../../assets/512px-Google__G__Logo.png';
import { loginRequest } from '../../reducers/auth/actions';
import { AppState } from '../../reducers';

const Login: React.FC = () => {
  const dispatch = useDispatch();
  const { isAuthenticated } = useSelector((state: AppState) => state.auth);

  const login = (): void => {
    dispatch(loginRequest());
  };

  useEffect(() => {
    // go to home page if already logged in
    if (isAuthenticated) dispatch(push('/'));
  });

  return (
    <div id={styles.login}>
      <div
        id={styles['google-login-btn']}
        role="button"
        tabIndex={0}
        onClick={login}
        onKeyDown={(e: KeyboardEvent<HTMLElement>): void => {
          // login only on "Enter" key press
          if (e.key === 'Enter') {
            login();
          }
        }}
      >
        <img width="20px" alt='Google "G" Logo' src={googleLogo} />
        <span className={styles.text}>Login with Google</span>
      </div>
    </div>
  );
};

export default Login;
