import React /* , { useState } */ from 'react';
// import { useSelector } from 'react-redux';

// import MegaGrid from '../../../components/MegaGrid/MegaGrid';
// import DetailsCard from '../DetailsCard/DetailsCard';
// import { findNextIndex, findPreviousIndex } from '../../../lib/array';
// import { AppState } from '../../../reducers';
// import { Player } from '../../../reducers/blocked-and-flagged-players/types';

const BlockedPlayers: React.FC = () => {
  // const keyMap = {
  //   playerId: 'PLAYER ID',
  //   reason: 'REASON',
  //   time: 'TIME'
  // };

  // const [selectedRow, setSelectedRow] = useState<Player | undefined>();
  // const { blocked } = useSelector(
  //   (state: AppState) => state.blockedAndFlaggedPlayers
  // );

  // const selectRow = (e: React.MouseEvent, sr: Player): void => {
  //   setSelectedRow(sr);
  // };

  // const onPreviousClick = (rowData: Player): void => {
  //   setSelectedRow(blocked[findPreviousIndex(blocked, rowData)]);
  // };

  // const onNextClick = (rowData: Player): void => {
  //   setSelectedRow(blocked[findNextIndex(blocked, rowData)]);
  // };

  return (
    <>
      {/* <MegaGrid<Player>
        data={blocked}
        keyMap={keyMap}
        group="date"
        onRowClick={selectRow}
        uniqueId="playerId"
        selectedRow={selectedRow}
      />
      {selectedRow && (
        <DetailsCard
          title="Blocked"
          data={selectedRow}
          keyMap={keyMap}
          onClose={(): void => setSelectedRow(undefined)}
          onNextClick={onNextClick}
          onPreviousClick={onPreviousClick}
        />
      )} */}
      <div className="coming-soon">coming soon</div>
    </>
  );
};

export default BlockedPlayers;
