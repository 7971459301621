import React, { useEffect } from 'react';
import { InputGroup } from '@blueprintjs/core';
import { Switch, Route } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import styles from './Players.module.scss';

import MegaButtonGroup, {
  RouteType
} from '../../../components/MegaButtonGroup/MegaButtonGroup';
import FlaggedPlayers from './FlaggedPlayers';
import BlockedPlayers from './BlockedPlayers';
import { WATSON_PLAYERS_GET_BLOCKED_AND_FLAGGED } from '../../../reducers/watson/blocked-and-flagged-players/ActionTypes';

interface PlayersProps {
  path: string;
}

const Players: React.FC<PlayersProps> = props => {
  const { path } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: WATSON_PLAYERS_GET_BLOCKED_AND_FLAGGED,
      payload: {}
    });
  });

  const routes: RouteType[] = [
    {
      title: 'Flagged',
      path: `${path}/flagged`,
      icon: 'flag',
      component: FlaggedPlayers
    },
    {
      title: 'Blocked',
      path: `${path}/blocked`,
      icon: 'blocked-person',
      component: BlockedPlayers
    }
  ];

  return (
    <div id={styles.players}>
      <div className={styles['user-header']}>
        <div className={styles.title}>Players</div>
        <InputGroup
          className={styles['search-input']}
          placeholder="Search using order number"
          type="search"
          leftIcon="search"
          large
        />
      </div>
      <div className={[styles['btn-group'], 'btn-group'].join(' ')}>
        <div style={{ paddingTop: '40px', width: '450px' }}>
          <MegaButtonGroup routes={routes} />
        </div>
      </div>
      <div className={styles.grid}>
        <Switch>
          {routes.map(route => {
            return (
              <Route
                key={route.path}
                exact
                path={route.path}
                component={route.component}
              />
            );
          })}
        </Switch>
      </div>
    </div>
  );
};

export default React.memo(Players);
