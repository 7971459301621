import React, { useState } from 'react';
import { Button, Intent } from '@blueprintjs/core';

import styles from './BlockPlayer.module.scss';

import FlagOrBlockPlayer from './FlagOrBlockPlayerDialog';
import DetailsDialog from './DetailsDialog';

export interface BlockPlayerProps {
  isBlocked: boolean;
  isBlockedReasonsDialogOpen: boolean;
  closeBlockedReasonsDialog(): void;
  unflag(): void;
  block(): void;
}

const blockingReasons: string[] = [
  'Using a modded version of the GetMega app.',
  'Device might be uncertified.',
  'boot-loader is unlocked.',
  'Running the device with a custom ROM.',
  'Running the GetMega app on an emulator.',
  'have received signs of system integrity being compromised or some other active attacks like API hooking.'
];

const BlockPlayer: React.FC<BlockPlayerProps> = props => {
  const {
    isBlocked,
    isBlockedReasonsDialogOpen,
    closeBlockedReasonsDialog,
    unflag,
    block
  } = props;

  const [isBlockingDialogOpen, setIsBlockingDialogOpen] = useState(false);
  const [selectedReasons, setSelectedReasons] = useState<string[]>([]);

  const blockPlayer = (sr: string[]): void => {
    setIsBlockingDialogOpen(false);

    if (sr.length) {
      setSelectedReasons(sr);

      unflag();
      block();
    }
  };

  return (
    <div id="block-player">
      <Button
        icon="blocked-person"
        className={styles.block}
        onClick={(): void => {
          setIsBlockingDialogOpen(true);
        }}
        intent={Intent.DANGER}
        disabled={isBlocked}
      >
        Block Player
      </Button>

      <FlagOrBlockPlayer
        isOpen={isBlockingDialogOpen}
        actionType="block"
        onCta={blockPlayer}
        reasons={blockingReasons}
        icon="blocked-person"
      />

      <DetailsDialog
        isOpen={isBlockedReasonsDialogOpen}
        reasons={selectedReasons}
        icon="blocked-person"
        title="Blocked Details"
        onClose={(): void => closeBlockedReasonsDialog()}
      />
    </div>
  );
};

export default React.memo(BlockPlayer);
