import React from 'react';
import { Spinner } from '@blueprintjs/core';
import PropTypes from 'prop-types';

import styles from './Loading.module.scss';

interface LoadingProps {
  message: string;
}

const Loading: React.FC<LoadingProps> = props => {
  const { message } = props;

  return (
    <div id={styles.loading}>
      <Spinner intent="danger" />
      <div>{message}</div>
    </div>
  );
};

Loading.propTypes = {
  message: PropTypes.string.isRequired
};

export default Loading;
