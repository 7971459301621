import React, { KeyboardEvent, useState, useCallback } from 'react';
import { InputGroup } from '@blueprintjs/core';
import { useDispatch, useSelector } from 'react-redux';

import styles from './SearchPlayer.module.scss';

import { AppState } from '../../../reducers';
import Loading from '../../../components/Loading/Loading';
import { getPlayer } from '../../../reducers/watson/player/actions';

const SearchPlayer: React.FC = () => {
  const dispatch = useDispatch();
  const { notFound, notFoundMessage, searchingPlayer } = useSelector(
    (state: AppState) => state.watson.player.profile
  );
  const [playerId, setPlayerId] = useState('');
  const authToken = useSelector((state: AppState) => state.auth.accessToken);
  const navigateTo = '/watson/home/activity-log';

  const searchPlayer = useCallback(
    (e: KeyboardEvent<HTMLInputElement>): void => {
      const pid = e.currentTarget.value;

      if (e.key === 'Enter' && pid) {
        dispatch(
          getPlayer({
            playerId: pid,
            authToken,
            navigateTo,
            fromKycRoute: false
          })
        );

        setPlayerId(pid);
      }
    },
    [authToken, dispatch]
  );

  return (
    <>
      <div id={styles['search-player']}>
        <InputGroup
          className={styles['search-input']}
          placeholder="Search using Player ID"
          type="search"
          leftIcon="search"
          large
          onKeyDown={searchPlayer}
        />
        {notFound && (
          <div className={styles['not-found-error']}>{notFoundMessage}</div>
        )}
        {searchingPlayer && (
          <Loading message={`searching for player id "${playerId}"`} />
        )}
      </div>
    </>
  );
};

export default SearchPlayer;
