import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import { createLogger } from 'redux-logger';
import { createBrowserHistory } from 'history';

import createRootReducer from '../reducers';
import rootSaga from '../sagas';

const sagaMiddleware = createSagaMiddleware();
export const history = createBrowserHistory();

const initialState = {};
const enhancers: any[] = []; // eslint-disable-line @typescript-eslint/no-explicit-any
const logger = createLogger({
  collapsed: true
});
const middleware = [sagaMiddleware, routerMiddleware(history), logger];
// const middleware = [sagaMiddleware, routerMiddleware(history)];

if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line no-underscore-dangle,@typescript-eslint/no-explicit-any
  const devToolsExtension = (window as any).__REDUX_DEVTOOLS_EXTENSION__;

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension());
  }
}

const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers);

const store = createStore(
  createRootReducer(history),
  initialState,
  composedEnhancers
);

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('../reducers', () =>
    store.replaceReducer(createRootReducer(history))
  );
}

export default store;

sagaMiddleware.run(rootSaga);
