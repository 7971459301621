import { combineReducers } from 'redux';

import player from './player';
import blockedAndFlaggedPlayers from './blocked-and-flagged-players';
import kyc from './kyc';

const watson = combineReducers({
  player,
  blockedAndFlaggedPlayers,
  kyc
});

export default watson;

export type Watson = ReturnType<typeof watson>;
