import React from 'react';
import PropTypes from 'prop-types';

import styles from './InfoCard.module.scss';

type InfoCardProps = {
  title: string;
  value: string;
  background?: string;
  height: string;
  width: string;
  valueFontSize?: string;
};

const InfoCard: React.FC<InfoCardProps> = ({
  title,
  value,
  background,
  height,
  width,
  valueFontSize
}) => {
  return (
    <div className={styles['info-card']} style={{ background, height, width }}>
      <div className={styles.title}>{title}</div>
      <div className={styles.value} style={{ fontSize: valueFontSize }}>
        {value}
      </div>
    </div>
  );
};

InfoCard.defaultProps = {
  background: '#FFEBD3',
  valueFontSize: '1.25rem'
};

InfoCard.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  background: PropTypes.string,
  height: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  valueFontSize: PropTypes.string
};

export default React.memo(InfoCard);
