import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Metadata } from '../../reducers/watson/player/metadata.types';
import { OrdersMap, Order } from '../../reducers/watson/player/orders.types';
import { resetSearchedOrder } from '../../reducers/watson/player/actions';

export interface UseSelectSearchedOrderProps {
  metadata: Metadata;
  orders: OrdersMap;
  category: string;
  setSelectedRow(order: Order): void;
}

const useSelectSearchedOrder = (props: UseSelectSearchedOrderProps): void => {
  const { metadata, orders, setSelectedRow, category } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    const { searchedOrderId, orderNotFound, searchingOrder } = metadata;

    if (
      searchedOrderId &&
      !orderNotFound &&
      !searchingOrder &&
      orders[searchedOrderId].Category === category
    ) {
      setSelectedRow(orders[searchedOrderId]);
      dispatch(resetSearchedOrder());
    }
  }, [metadata, orders, dispatch, category, setSelectedRow]);
};

export default useSelectSearchedOrder;
