import * as types from './ActionTypes';
import { User, AuthAction } from './types';

export const loginRequest = (): AuthAction => ({
  type: types.LOGIN_REQUEST,
  payload: {}
});

export const loginSuccess = (user: User): AuthAction => ({
  type: types.LOGIN_SUCCESS,
  payload: {
    user,
    accessToken:
      user.ma || (user.stsTokenManager && user.stsTokenManager.accessToken),
    refreshToken: user.refreshToken
  }
});

export const loginFailure = (error: Error): AuthAction => ({
  type: types.LOGIN_ERROR,
  payload: { error }
});

export const logoutRequest = (): AuthAction => ({
  type: types.LOGOUT_REQUEST,
  payload: {}
});

export const logoutSuccess = (): AuthAction => ({
  type: types.LOGOUT_SUCCESS,
  payload: {}
});

export const logoutFailure = (error: Error): AuthAction => ({
  type: types.LOGOUT_ERROR,
  payload: { error }
});
