import React, { useState, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { findPreviousIndex, findNextIndex } from '../../../../lib/array';
import { AppState } from '../../../../reducers';
import DetailsCard from '../../DetailsCard/DetailsCard';
import MegaGrid from '../../../../components/MegaGrid/MegaGrid';
import {
  WATSON_PLAYER_SYNC_TIMELINE,
  WATSON_PLAYER_CANCEL_TIMELINE_SYNC
} from '../../../../reducers/watson/player/ActionTypes';
import { Event } from '../../../../reducers/watson/player/timeline.types';

const ActivityLog: React.FC = () => {
  const detailsKeyMap = {
    // id: 'ID',
    // Type: 'TYPE',
    'DisplayActivity.EventType': 'EVENT TYPE',
    'DisplayActivity.Title': 'TITLE',
    'DisplayActivity.Subtitle': 'SUBTITLE'
  };

  const dispatch = useDispatch();
  const { timeline, profile } = useSelector(
    (state: AppState) => state.watson.player
  );
  const playerId = profile.PlayerId;
  const data = Object.values(timeline).map(event => ({
    ...event,
    DisplayActivity: {
      ...event.DisplayActivity,
      Title: event.DisplayActivity.Title.replace(/(<([^>]+)>)/gi, ''),
      Subtitle: event.DisplayActivity.Subtitle.replace(/(<([^>]+)>)/gi, '')
    }
  }));
  const isLastPage = false;

  const [selectedRow, setSelectedRow] = useState<Event | undefined>();

  const selectRow = (e: React.MouseEvent, sr: Event): void => {
    setSelectedRow(sr);
  };

  const onPreviousClick = (rowData: Event): void => {
    setSelectedRow(data[findPreviousIndex(data, rowData)]);
  };

  const onNextClick = (rowData: Event): void => {
    setSelectedRow(data[findNextIndex(data, rowData)]);
  };

  useEffect(() => {
    // sync players timeline
    if (playerId) {
      dispatch({
        type: WATSON_PLAYER_SYNC_TIMELINE,
        payload: { playerId }
      });
    }

    return (): void => {
      // cancel timeline sync on unmount
      dispatch({
        type: WATSON_PLAYER_CANCEL_TIMELINE_SYNC,
        payload: {}
      });
    };
  }, [dispatch, playerId]);

  return (
    <div>
      <MegaGrid<Event>
        data={data}
        keyMap={{ formattedTime: 'TIME', ...detailsKeyMap }}
        group="formattedCreatedAt"
        onRowClick={selectRow}
        uniqueId="id"
        selectedRow={selectedRow}
      />
      {isLastPage && <div className="no-more-orders">No more orders !!!</div>}
      {selectedRow && (
        <DetailsCard
          title="PASSES"
          data={selectedRow}
          keyMap={detailsKeyMap}
          onClose={(): void => setSelectedRow(undefined)}
          onNextClick={onNextClick}
          onPreviousClick={onPreviousClick}
        />
      )}
    </div>
  );
};

export default ActivityLog;
