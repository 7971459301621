import React from 'react';
import { Link } from 'react-router-dom';

import styles from './DashboardChooser.module.scss';
// import OmegaLogo from '../../assets/omega.svg';

const DashboardChooser: React.FC = () => {
  return (
    <div className={styles['dashboard-chooser']}>
      <div className={styles['dashboard-selector']}>
        <Link to="/watson/home/search">
          {/* <img src={OmegaLogo} alt="Watson" /> */}
          Watson
        </Link>
      </div>
      {/* <div className={styles['dashboard-selector']}>
        <img src={OmegaLogo} alt="Omega" />
      </div>
      <div className={styles['dashboard-selector']}>
        <img src={OmegaLogo} alt="KYC" />
      </div> */}
    </div>
  );
};

export default DashboardChooser;
