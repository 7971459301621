import { fork, take, cancel } from 'redux-saga/effects';
import { DocumentData, QuerySnapshot } from '@firebase/firestore-types';
import { Task } from 'redux-saga';

import rsf from '../../../firestore';
import { Wallets } from '../../../reducers/watson/player/wallets.types';
import * as types from '../../../reducers/watson/player/ActionTypes';

const getStoreWalletsAction = (
  snapshot: QuerySnapshot
): {
  type: string;
  payload: Wallets;
} => {
  const wallets: Wallets = {};
  snapshot.forEach((wallet: DocumentData) => {
    const w = wallet.data();
    w.id = wallet.id;

    if (w.Type !== 'Winnings' && w.Type !== 'Deposit') {
      return;
    }

    const type = w.Type === 'Winnings' ? 'Winnings' : 'Deposit';
    wallets[type] = w;
  });

  return {
    type: types.WATSON_PLAYER_STORE_WALLETS,
    payload: wallets
  };
};

export default function*(action: {
  type: string;
  payload: { playerId: string };
}): Generator {
  const { playerId } = action.payload;

  const task = yield fork(
    // eslint-disable-next-line
    // @ts-ignore
    rsf.firestore.syncCollection,
    `players/${playerId}/wallets`,
    {
      successActionCreator: getStoreWalletsAction
    }
  );

  yield take(types.WATSON_PLAYER_CANCEL_WALLETS_SYNC);
  yield cancel(task as Task);
}
