import { fork, take, cancel } from 'redux-saga/effects';
import firebase from 'firebase/app';
import { DocumentData, QuerySnapshot } from '@firebase/firestore-types';
import { Task } from 'redux-saga';
import moment from 'moment';

import rsf from '../../../firestore';
import * as types from '../../../reducers/watson/player/ActionTypes';
import { Pass } from '../../../reducers/watson/player/passes.types';

const getStorePassesAction = (
  snapshot: QuerySnapshot
): {
  type: string;
  payload: { passes: Pass[] };
} => {
  const passes: Pass[] = [];
  snapshot.forEach((doc: DocumentData) => {
    const timestamp = new Date(doc.data().CreatedAt.seconds * 1000);
    const data = doc.data();

    passes.push({
      ...data,
      id: doc.id,
      formattedCreatedAt: moment(timestamp).format('DD/MM/YYYY'),
      formattedTime: moment(timestamp).format('hh:mm A'),
      startTime: moment(data.StartTime.seconds * 1000).format(
        'DD/MM/YYYY hh:mm A'
      ),
      expiryAt: moment(data.ExpiryAt.seconds * 1000).format(
        'DD/MM/YYYY hh:mm A'
      )
    });
  });

  return {
    type: types.WATSON_PLAYER_STORE_PASSES,
    payload: { passes }
  };
};

export default function*(action: {
  type: string;
  payload: { playerId: string };
}): Generator {
  const { playerId } = action.payload;

  const query = firebase
    .firestore()
    .collection(`players/${playerId}/private/encompass/passes`)
    // .where('State', '==', 'Unused')
    // .where('ExpiryAt', '>', new Date())
    .orderBy('ExpiryAt', 'desc');

  const task = yield fork(
    // eslint-disable-next-line
    // @ts-ignore
    rsf.firestore.syncCollection,
    query,
    {
      successActionCreator: getStorePassesAction
    }
  );

  yield take(types.WATSON_PLAYER_CANCEL_PASSES_SYNC);
  yield cancel(task as Task);
}
