import { useState, useEffect, useCallback } from 'react';

const getScrollXY = (): Array<number> => {
  let scrOfX = 0;
  let scrOfY = 0;
  if (typeof window.pageYOffset === 'number') {
    // Netscape compliant
    scrOfY = window.pageYOffset;
    scrOfX = window.pageXOffset;
  } else if (
    document.body &&
    (document.body.scrollLeft || document.body.scrollTop)
  ) {
    // DOM compliant
    scrOfY = document.body.scrollTop;
    scrOfX = document.body.scrollLeft;
  } else if (
    document.documentElement &&
    (document.documentElement.scrollLeft || document.documentElement.scrollTop)
  ) {
    // IE6 standards compliant mode
    scrOfY = document.documentElement.scrollTop;
    scrOfX = document.documentElement.scrollLeft;
  }
  return [scrOfX, scrOfY];
};

// taken from http://james.padolsey.com/javascript/get-document-height-cross-browser/
const getDocHeight = (): number => {
  const D = document;
  return Math.max(
    D.body.scrollHeight,
    D.documentElement.scrollHeight,
    D.body.offsetHeight,
    D.documentElement.offsetHeight,
    D.body.clientHeight,
    D.documentElement.clientHeight
  );
};

const useDetectScrollBottom = (): boolean => {
  const [isAtBottom, setIsAtBottom] = useState(false);

  const listener = useCallback((): void => {
    const newIsAtBottom =
      Math.abs(getDocHeight() - (getScrollXY()[1] + window.innerHeight)) < 5;

    if (newIsAtBottom !== isAtBottom) setIsAtBottom(newIsAtBottom);
  }, [isAtBottom]);

  useEffect(() => {
    document.addEventListener('scroll', listener);

    return (): void => {
      document.removeEventListener('scroll', listener);
    };
  }, [listener]);

  return isAtBottom;
};

export default useDetectScrollBottom;
