import { fork, take, cancel } from 'redux-saga/effects';
import firebase from 'firebase/app';
import { DocumentData, QuerySnapshot } from '@firebase/firestore-types';
import { Task } from 'redux-saga';
import moment from 'moment';

import rsf from '../../../firestore';
import * as types from '../../../reducers/watson/player/ActionTypes';
import { Event } from '../../../reducers/watson/player/timeline.types';

const getStoreTimelineAction = (
  snapshot: QuerySnapshot
): {
  type: string;
  payload: { events: Event[] };
} => {
  const events: Event[] = [];
  snapshot.forEach((doc: DocumentData) => {
    const timestamp = new Date(doc.data().CreatedAt.seconds * 1000);
    const data = doc.data();

    events.push({
      ...data,
      id: doc.id,
      formattedCreatedAt: moment(timestamp).format('DD/MM/YYYY'),
      formattedTime: moment(timestamp).format('hh:mm A')
    });
  });

  return {
    type: types.WATSON_PLAYER_STORE_TIMELINE,
    payload: { events }
  };
};

export default function*(action: {
  type: string;
  payload: { playerId: string };
}): Generator {
  const { playerId } = action.payload;

  const query = firebase
    .firestore()
    .collection(`players/${playerId}/private/timeline/activities`)
    .limit(5)
    .orderBy('CreatedAt', 'desc');

  const task = yield fork(
    // eslint-disable-next-line
    // @ts-ignore
    rsf.firestore.syncCollection,
    query,
    {
      successActionCreator: getStoreTimelineAction
    }
  );

  yield take(types.WATSON_PLAYER_CANCEL_TIMELINE_SYNC);
  yield cancel(task as Task);
}
