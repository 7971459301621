import { call, take, put, fork, cancel } from 'redux-saga/effects';
import { Task } from 'redux-saga';
import { DocumentData, QuerySnapshot, Query } from '@firebase/firestore-types';

import rsf from '../../../firestore';
import formatOrder from '../../../lib/format-watson-order';
import {
  Order,
  OrderCategories
} from '../../../reducers/watson/player/orders.types';
import * as types from '../../../reducers/watson/player/ActionTypes';
import {
  setSearchedOrderId,
  orderNotFound,
  orderFound
} from '../../../reducers/watson/player/actions';

const formatOrders = (
  snapshot: QuerySnapshot
): {
  type: string;
  payload: Order[];
} => {
  const orders: Order[] = [];

  snapshot.forEach((doc: DocumentData) => {
    const order = formatOrder(doc);
    orders.push(order as Order);
  });

  return {
    type: types.PLAYER_STORE_ORDERS,
    payload: orders
  };
};

export function* syncOrders(action: {
  type: string;
  payload: { query: Query };
}): Generator {
  const { query } = action.payload;
  // eslint-disable-next-line
  // @ts-ignore
  const task: Task = yield fork(rsf.firestore.syncCollection, query, {
    successActionCreator: formatOrders
  });

  yield take(types.WATSON_PLAYER_CANCEL_ORDERS_SYNC);
  yield cancel(task);
}

export function* searchOrder(action: {
  type: string;
  payload: { orderId: string };
}): Generator {
  const { orderId } = action.payload;

  try {
    yield put(setSearchedOrderId(orderId));

    const snapshot = yield call(
      rsf.firestore.getDocument,
      `central_orders/${orderId}`
    );

    const order: Order = formatOrder(snapshot as DocumentData);
    yield put({ type: types.PLAYER_STORE_ORDERS, payload: [order] });
    yield put(orderFound());
  } catch (error) {
    yield put(orderNotFound());
  }
}

export function* getOrders(action: {
  type: string;
  payload: {
    query: Query;
    category?: OrderCategories;
  };
}): Generator {
  const { query, category } = action.payload;

  try {
    if (category) {
      yield put({
        type: types.WATSON_PLAYER_METADATA_SET_IS_FETCHING_ORDER,
        payload: {
          isFetchingOrders: {
            [category]: true
          }
        }
      });
    }
    // eslint-disable-next-line
    // @ts-ignore
    const snapshot = yield call(rsf.firestore.getCollection, query);

    const storeOrdersAction = formatOrders(snapshot as QuerySnapshot);

    if (storeOrdersAction.payload.length) {
      yield put(storeOrdersAction);
    }

    if (category) {
      yield put({
        type: types.WATSON_PLAYER_METADATA_SET_IS_FETCHING_ORDER,
        payload: {
          isFetchingOrders: {
            [category]: false
          }
        }
      });
    }
  } catch (error) {
    // error fetching orders
    if (category) {
      yield put({
        type: types.WATSON_PLAYER_METADATA_SET_IS_FETCHING_ORDER,
        payload: {
          isFetchingOrders: {
            [category]: false
          }
        }
      });
    }
  }
}
