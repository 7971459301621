import React, { useState, useEffect } from 'react';
import { ButtonGroup, Button, IconName, Tag } from '@blueprintjs/core';
import { useHistory } from 'react-router';
import { History } from 'history';

import styles from './MegaButtonGroup.module.scss';

export type RouteType = {
  title: string;
  path: string;
  icon?: IconName;
  component?: React.FC;
  tag?: string;
};

type MegaButtonGroupProps = {
  routes: RouteType[];
};

interface MegaButtonProps {
  route: RouteType;
  i: number;
  totalRoutes: number;
  history: History;
  prevPath: string;
}

const shouldNotReRenderButton = (
  prevProps: MegaButtonProps,
  nextProps: MegaButtonProps
): boolean => {
  // if it has tag
  // then always render it
  if (nextProps.route.tag) return false;

  // it should only be rendered
  // if it was selected previously
  // or if its selected currently
  return !(
    nextProps.prevPath === nextProps.route.path ||
    nextProps.history.location.pathname === nextProps.route.path
  );
};

const MegaButton: React.FC<MegaButtonProps> = React.memo(props => {
  const { route, i, totalRoutes, history } = props;
  const currentPath = history.location.pathname;

  return (
    <Button
      key={route.path}
      minimal
      onClick={(): void => history.push(route.path)}
      className={[
        styles.btn,
        currentPath === route.path ? styles.selected : '',
        i === 0 ? styles['round-left'] : '',
        i === totalRoutes - 1 ? styles['round-right'] : ''
      ].join(' ')}
      icon={route.icon}
    >
      {route.title}
      {route.tag && (
        <Tag intent="warning" className={styles.tag}>
          {route.tag}
        </Tag>
      )}
    </Button>
  );
}, shouldNotReRenderButton);

const MegaButtonGroup: React.FC<MegaButtonGroupProps> = props => {
  const { routes } = props;
  const history = useHistory();
  const [prevPath, setPrevPath] = useState('');

  useEffect(() => {
    setPrevPath(history.location.pathname);
  }, [history.location.pathname]);

  return (
    <ButtonGroup fill large className={styles['mega-button-group']}>
      {routes.map((route, i) => {
        return (
          <MegaButton
            route={routes[i]}
            i={i}
            totalRoutes={routes.length}
            key={route.path}
            history={history}
            prevPath={prevPath}
          />
        );
      })}
    </ButtonGroup>
  );
};

export default MegaButtonGroup;
