import React from 'react';
import { Dialog, Classes, Icon, IconName } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import PropTypes from 'prop-types';

import styles from './DetailsDialog.module.scss';

type DetailsDialogProps = {
  isOpen: boolean;
  reasons: string[];
  icon: IconName;
  title: string;
  onClose(isOpen: boolean): void;
};

const DetailsDialog: React.FC<DetailsDialogProps> = props => {
  const { reasons, icon, title, onClose, isOpen } = props;

  return (
    <Dialog
      isOpen={isOpen}
      className={styles['flag-or-block-details']}
      onClose={(): void => onClose(false)}
      canOutsideClickClose
    >
      <div className={Classes.DIALOG_BODY}>
        <div className={styles.title}>{title}</div>
        <div className={styles['reason-text']}>REASON</div>
        <div className={styles.reasons}>
          {reasons.map(reason => {
            return (
              <div key={reason} className={styles.reason}>
                <Icon icon={icon} className={styles.icon} />
                <span>{reason}</span>
              </div>
            );
          })}
        </div>
      </div>
    </Dialog>
  );
};

DetailsDialog.propTypes = {
  reasons: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  icon: PropTypes.oneOf(Object.values(IconNames)).isRequired,
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired
};

export default DetailsDialog;
