import React, { useState } from 'react';
import {
  Dialog,
  Classes,
  Checkbox,
  IconName,
  Button,
  Intent
} from '@blueprintjs/core';
import PropTypes from 'prop-types';
import { IconNames } from '@blueprintjs/icons';

import styles from './FlagOrBlockPlayerDialog.module.scss';

const ActionTypes = { flag: 'flag', block: 'block' };
export type ActionType = typeof ActionTypes[keyof typeof ActionTypes];

type FlagOrBlockUserType = {
  isOpen: boolean;
  actionType: ActionType;
  onCta(selectedReasons: string[]): void;
  reasons: string[];
  icon?: IconName;
};

const FlagOrBlockPlayer: React.FC<FlagOrBlockUserType> = props => {
  const { onCta, reasons, actionType, icon, isOpen } = props;
  const [selectedReasons, setSelectedReasons] = useState<string[]>([]);

  return (
    <Dialog
      isOpen={isOpen}
      onClose={(): void => {
        setSelectedReasons([]);
        onCta([]);
      }}
      className={styles['flag-or-block-user']}
    >
      <div className={Classes.DIALOG_BODY}>
        <div className={styles.title}>
          {actionType}
          &nbsp; Player
        </div>
        <div className={styles.reason}>REASON</div>
        <div className={styles['reason-list']}>
          {reasons.map(reason => {
            return (
              <div key={reason}>
                <Checkbox
                  name={reason}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                    if (e.target.checked) {
                      const sr = [...selectedReasons, reason];
                      setSelectedReasons(sr);
                    } else {
                      const index = selectedReasons.indexOf(reason);
                      const sr = [
                        ...selectedReasons.slice(0, index),
                        ...selectedReasons.slice(index + 1)
                      ];
                      setSelectedReasons(sr);
                    }
                  }}
                >
                  {reason}
                </Checkbox>
              </div>
            );
          })}
        </div>
        <div className={styles['btn-div']}>
          <Button
            className={styles['action-btn']}
            icon={icon}
            disabled={selectedReasons.length === 0}
            intent={actionType === 'flag' ? Intent.WARNING : Intent.DANGER}
            onClick={(): void => onCta(selectedReasons)}
          >
            {actionType}
            &nbsp; Player
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

FlagOrBlockPlayer.propTypes = {
  onCta: PropTypes.func.isRequired,
  reasons: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  actionType: PropTypes.oneOf(Object.values(ActionTypes)).isRequired,
  icon: PropTypes.oneOf(Object.values(IconNames)).isRequired
};

export default FlagOrBlockPlayer;
