import { call, put } from 'redux-saga/effects';

import api from './api';
import {
  KycDocumentData,
  GetPanDataAction,
  GetOtherDocumentDataAction,
  UpdateKycOrderAction
} from '../../../reducers/watson/player/kycData.types';
import {
  storePanData,
  storeOtherDocumentData
} from '../../../reducers/watson/player/actions';
import MegaToaster from '../../../components/MegaToaster/MegaToaster';

const baseUrl = process.env.REACT_APP_FETCH_URL;

export function* getPanData(
  action: GetPanDataAction
): Generator<object, void, KycDocumentData> {
  const apiEndpoint = `${baseUrl}/twirp/pb.Watson/GetPanDetails`;
  const { playerId, authToken } = action.payload;
  const body = { player_id: playerId };

  const panData = yield call(api.getKycDocumentData, {
    apiEndpoint,
    body,
    authToken
  });

  // reset data before storing
  // pan data fetching api might fail
  // previous player's kyc data will still be there in state
  // yield put(resetKycDocumentData());

  panData.order_id =
    panData.upload_count_info &&
    panData.upload_count_info.order_history &&
    panData.upload_count_info.order_history[0].split('/')[1];

  yield put(storePanData(panData));
}

export function* getOtherDocumentData(
  action: GetOtherDocumentDataAction
): Generator<object, void, KycDocumentData> {
  const apiEndpoint = `${baseUrl}/twirp/pb.Watson/GetKycDocumentDetails`;
  const { orderId, authToken } = action.payload;
  const body = { order_id: orderId };

  const otherDocumentData = yield call(api.getKycDocumentData, {
    apiEndpoint,
    body,
    authToken
  });

  if (otherDocumentData.document_type === 'PAN') {
    otherDocumentData.order_id = orderId;
    yield put(storePanData(otherDocumentData));
  } else {
    otherDocumentData.order_id = orderId;

    yield put(storeOtherDocumentData(otherDocumentData));
  }
}

export function* updateKycDocumentStatus(
  action: UpdateKycOrderAction
): Generator {
  const data = yield call(api.updateKycDocumentStatus, action.payload);

  if (data !== null && (data as KycDocumentData).document_type) {
    const { documentType, orderId } = action.payload;

    MegaToaster.show({
      message: 'Order status updated!',
      intent: 'success'
    });

    if (documentType === 'PAN') {
      yield put(
        storePanData({ ...(data as KycDocumentData), order_id: orderId })
      );
    } else {
      yield put(
        storeOtherDocumentData({
          ...(data as KycDocumentData),
          order_id: orderId
        })
      );
    }
  } else {
    MegaToaster.show({
      message: 'Error updating order status',
      intent: 'danger'
    });
  }
}
