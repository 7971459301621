import {
  BlockUnblockPlayerPayload,
  IncrementWildUploadLimitPayload
} from '../../../reducers/watson/player/index.types';
import {
  UploadCountInfo,
  UpdateKycOrderPayload,
  KycDocumentData
} from '../../../reducers/watson/player/kycData.types';

const baseUrl = process.env.REACT_APP_FETCH_URL;

const getKycDocumentData = async <T>({
  apiEndpoint,
  body,
  authToken
}: {
  apiEndpoint: string;
  body: { [index: string]: any };
  authToken: string;
}): Promise<T> => {
  return fetch(apiEndpoint, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${authToken}`
    },
    body: JSON.stringify(body)
  })
    .then(response => response.json())
    .then(data => data)
    .catch(error => {
      return error;
    });
};

const blockUnblockApiHandler = ({
  apiEndpoint,
  playerId,
  authToken
}: {
  apiEndpoint: string;
  playerId: string;
  authToken: string;
}): Promise<void> => {
  return fetch(apiEndpoint, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${authToken}`
    },
    body: JSON.stringify({ player_id: playerId })
  })
    .then(response => response.json())
    .then(data => data)
    .catch(() => {
      return {};
    });
};

const blockPlayer = async ({
  playerId,
  authToken
}: BlockUnblockPlayerPayload): Promise<void> => {
  const apiEndpoint = `${baseUrl}/twirp/pb.ManageUser/Disable`;

  await blockUnblockApiHandler({
    apiEndpoint,
    playerId,
    authToken
  });
};

const unblockPlayer = async ({
  playerId,
  authToken
}: BlockUnblockPlayerPayload): Promise<void> => {
  const apiEndpoint = `${baseUrl}/twirp/pb.ManageUser/Enable`;

  await blockUnblockApiHandler({
    apiEndpoint,
    playerId,
    authToken
  });
};

const incrementWildUploadLimit = async ({
  body,
  authToken
}: IncrementWildUploadLimitPayload): Promise<UploadCountInfo> => {
  const apiEndpoint = `${baseUrl}/twirp/pb.Watson/ProvideWildUploads`;

  return fetch(apiEndpoint, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${authToken}`
    },
    body: JSON.stringify(body)
  })
    .then(response => response.json())
    .then(data => data)
    .catch(() => {
      return {};
    });
};

const updateKycDocumentStatus = async ({
  orderId,
  isVerified,
  comment,
  authToken,
  userStatusText,
  cardDetails
}: UpdateKycOrderPayload): Promise<KycDocumentData | null> => {
  const apiEndpoint = `${baseUrl}/twirp/pb.Watson/UpdateDocumentStatus`;
  const body = {
    order_id: orderId,
    is_verified: isVerified,
    verification_comment: comment,
    user_status_text: userStatusText,
    card_details: cardDetails
  };

  if (isVerified) {
    Reflect.deleteProperty(body, 'user_status_text');
  }

  return fetch(apiEndpoint, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${authToken}`
    },
    body: JSON.stringify(body)
  })
    .then(response => response.json())
    .then((data: KycDocumentData) => data)
    .catch(() => {
      return null;
    });
};

const api = {
  blockPlayer,
  unblockPlayer,
  incrementWildUploadLimit,
  updateKycDocumentStatus,
  getKycDocumentData
};

export default api;
