import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import MegaGrid from '../../../components/MegaGrid/MegaGrid';
import { AppState } from '../../../reducers';
import { KycOrder } from '../../../reducers/watson/kyc/types';
import useGetPlayerDetails from '../../hooks/useGetPlayerDetails';

const Pending: React.FC = () => {
  const dispatch = useDispatch();
  const { orders, metadata } = useSelector(
    (state: AppState) => state.watson.kyc
  );
  const pending = Object.values(orders)
    .filter(
      kycOrder =>
        kycOrder.State === 'Manual_Supervision' &&
        (metadata.searchedPlayerId
          ? metadata.searchedPlayerId === kycOrder.PlayerId
          : true)
    )
    .sort((o1, o2) => (o1.CreatedAt.seconds > o2.CreatedAt.seconds ? -1 : 1));
  const authToken = useSelector((state: AppState) => state.auth.accessToken);

  const keyMap = {
    formattedTime: 'TIME',
    id: 'ORDER ID',
    PlayerId: 'PLAYER ID',
    'Product.KycDocumentType': 'DOCUMENT TYPE'
  };

  const getPlayerDetails = useGetPlayerDetails(dispatch, authToken);

  return (
    <div id="pending">
      <MegaGrid<KycOrder>
        data={pending}
        keyMap={keyMap}
        group="formattedCreatedAt"
        onRowClick={getPlayerDetails}
        uniqueId="id"
      />
    </div>
  );
};

export default Pending;
