import React, { useMemo, useEffect, useState } from 'react';
import firebase from 'firebase/app';
import moment from 'moment';
// import { useSelector } from 'react-redux';

import './Reward.module.scss';

import useSyncOrders from '../../../hooks/useSyncOrders';
// import useDetectScrollBottom from '../../../../hooks/useDetectScrollBottom';
// import useFetchMoreOrders from '../../../hooks/useFetchMoreOrders';
import useSelectSearchedOrder from '../../../hooks/useSelectSearchedOrder';
import useInitializeOrdersComponent from '../../../hooks/useInitializeOrdersComponent';
import PlayerOrderView from '../PlayerOrdersView';
// import { AppState } from '../../../../reducers';

const Rewards: React.FC = () => {
  const detailsKeyMap = {
    Title: 'TITLE',
    id: 'ORDER ID',
    // 'Product.Type': 'Type',
    amountWithCurrency: 'AMOUNT',
    'Product.PassProps.Passes.length': 'PASSES',
    'Product.RewardProps.RewardType': 'REWARD TYPE',
    'Product.RewardProps.InitiatorId': 'INITIATOR ID',
    'Product.RewardProps.Reason': 'REASON',
    State: 'STATE'
  };
  const category = 'Reward';
  // const { isFetchingOrders: ifo } = useSelector(
  //   (state: AppState) => state.watson.player.metadata
  // );

  const {
    playerId,
    selectedRow,
    selectRow,
    setSelectedRow,
    onPreviousClick,
    onNextClick,
    // isLastPage,
    data,
    orders,
    metadata
  } = useInitializeOrdersComponent({ category });

  useSelectSearchedOrder({
    category,
    setSelectedRow,
    metadata,
    orders
  });

  const rewardData = data
    .map(r => ({
      ...r,
      amountWithCurrency: `${(r.Product.RewardProps &&
        r.Product.RewardProps.Amount &&
        `${
          r.Product.RewardProps.Amount
        } ${r.Product.RewardProps.Currency.replace('INR', '₹')}`) ||
        ''}`,
      Title:
        r.Product.RewardProps && r.Product.RewardProps.Currency === 'INR'
          ? r.Product.Name
          : r.Title
    }))
    .sort((o1, o2) => (o1.CreatedAt.seconds > o2.CreatedAt.seconds ? -1 : 1));

  const [startDate, setStartDate] = useState(new Date());
  useEffect(() => {
    setStartDate(
      new Date(
        moment()
          .endOf('day')
          .subtract(400, 'days')
          .format()
      )
    );
  }, []);

  const query = useMemo(
    () =>
      firebase
        .firestore()
        .collection('central_orders')
        .where('PlayerId', '==', playerId)
        .where('Category', '==', category)
        .where('State', 'in', ['Complete', 'Failed', 'Processing', 'Pending'])
        .orderBy('CreatedAt', 'desc'),
    [playerId, category]
  );
  useSyncOrders({ playerId, query, startDate });

  // const isAtBottom = useDetectScrollBottom();
  // useFetchMoreOrders({
  //   isAtBottom,
  //   isLastPage,
  //   playerId,
  //   query,
  //   startDate,
  //   category
  // });

  // const isFetchingOrders = !!ifo.Reward;

  return (
    <PlayerOrderView
      detailsTitle="Rewards"
      data={rewardData}
      keyMap={{ formattedTime: 'TIME', ...detailsKeyMap }}
      detailsKeymap={detailsKeyMap}
      isLastPage
      isFetchingOrders={false}
      selectedRow={selectedRow}
      selectRow={selectRow}
      setSelectedRow={setSelectedRow}
      onNextClick={onNextClick}
      onPreviousClick={onPreviousClick}
    />
  );
};

export default Rewards;
