import React, { useState, ChangeEvent } from 'react';
import {
  RadioGroup,
  TextArea,
  Button,
  Radio,
  HTMLSelect
} from '@blueprintjs/core';
import { useDispatch } from 'react-redux';

import styles from './PlayerKyc.module.scss';
import {
  acceptKycOrder,
  rejectKycOrder
} from '../../../../reducers/watson/player/actions';
import { DetailsByUser } from '../../../../reducers/watson/player/kycData.types';

interface AcceptOrRejectProps {
  orderId: string;
  authToken: string;
  documentType: string;
  cardDetails: DetailsByUser;
}

const AcceptOrReject: React.FC<AcceptOrRejectProps> = props => {
  const { orderId, authToken, documentType, cardDetails } = props;
  const dispatch = useDispatch();
  const [status, setStatus] = useState('reject');
  const [comment, setComment] = useState('');
  const userStatusTextArray: {
    [index: string]: string;
  } = {
    'Invalid Image': 'INVALID_IMAGE',
    'Info Mismatch': 'INFO_MISMATCH',
    'Different User Document': 'DIFFERENT_USER_DOCUMENT',
    'Minor User': 'MINOR_USER'
  };
  const [userStatusText, setUserStatusText] = useState(
    userStatusTextArray['Invalid Image']
  );

  const updateKycDocumentStatus = (): void => {
    if (status === 'accept') {
      dispatch(
        acceptKycOrder({
          orderId,
          authToken,
          comment,
          documentType,
          cardDetails,
          // send user_status_text only in case of reject
          userStatusText: ''
        })
      );
    } else {
      dispatch(
        rejectKycOrder({
          orderId,
          authToken,
          comment,
          documentType,
          cardDetails,
          userStatusText
        })
      );
    }
  };

  const handleUserStatusTextChange = (
    e: ChangeEvent<HTMLSelectElement>
  ): void => {
    setUserStatusText(userStatusTextArray[e.currentTarget.value]);
  };

  return (
    <div id={styles['action-div']}>
      <div>
        <RadioGroup
          onChange={(e: React.FormEvent<HTMLInputElement>): void => {
            setStatus(e.currentTarget.value);
          }}
          selectedValue={status}
          inline
        >
          <Radio label="Accept" value="accept" />
          <Radio label="Reject" value="reject" />
        </RadioGroup>
      </div>
      {status === 'reject' && (
        <div className={styles['user-status-text']}>
          <span style={{ marginRight: '16px' }}>Reason</span>
          <HTMLSelect
            options={Object.keys(userStatusTextArray)}
            onChange={handleUserStatusTextChange}
          />
        </div>
      )}
      <div className="reason">
        <TextArea
          large
          value={comment}
          onChange={(e: ChangeEvent<HTMLTextAreaElement>): void => {
            setComment(e.target.value);
          }}
          className={styles.comment}
        />
      </div>
      <Button
        className={styles['send-btn']}
        onClick={(): void => updateKycDocumentStatus()}
        disabled={comment.trim() === ''}
        intent="warning"
      >
        Send
      </Button>
    </div>
  );
};

export default AcceptOrReject;
