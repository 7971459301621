import { all, takeLatest, put, call, takeEvery } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import getPublicProfile from './profile';
import syncWallets from './wallets';
import syncPasses from './passes';
import syncTimeline from './timeline';
import { searchOrder, syncOrders, getOrders } from './orders';
import getAddress from './address';
import getTags from './tags';
import {
  getPanData,
  getOtherDocumentData,
  updateKycDocumentStatus
} from './kyc';
import * as types from '../../../reducers/watson/player/ActionTypes';
import {
  GetPlayerAction,
  IncrementWildUploadLimitAction
} from '../../../reducers/watson/player/index.types';
import { Profile } from '../../../reducers/watson/player/profile.types';
import { Order } from '../../../reducers/watson/player/orders.types';
import { Address } from '../../../reducers/watson/player/address.types';
import { Tag } from '../../../reducers/watson/player/tags.types';
import { Wallets } from '../../../reducers/watson/player/wallets.types';
import {
  setFromKycRoute,
  updateWildUploadLimit,
  resetKycDocumentData
} from '../../../reducers/watson/player/actions';
import api from './api';
import { UploadCountInfo } from '../../../reducers/watson/player/kycData.types';
import syncKycOrders from './kycOrders';

type YieldedType = Profile & Order[] & Address[] & Tag[] & Wallets;

export function* getPlayer(
  action: GetPlayerAction
): Generator<object, void, YieldedType> {
  const message = `Oops! Player profile not found for id: "${action.payload.playerId}". Enter any other value.`;

  try {
    const {
      playerId,
      navigateTo,
      fromKycRoute,
      kycDocOrderId
    } = action.payload;
    yield put({ type: types.WATSON_SEARCHING_PLAYER, payload: {} });
    yield put(setFromKycRoute(fromKycRoute));
    const profile: Profile = yield call(getPublicProfile, playerId);

    if (profile.DisplayName) {
      // if player is found fetch wallet, address, tags details
      const sagas = [getAddress(playerId), getTags(profile)];

      // sync players kyc Orders
      yield put({
        type: types.WATSON_PLAYER_SYNC_KYC_ORDERS,
        payload: { playerId }
      });

      yield put(resetKycDocumentData());
      yield put({
        type: types.WATSON_PLAYER_KYC_SET_SELECTED_ORDER_ID,
        payload: { selectedOrderId: kycDocOrderId }
      });

      const [address = [], tags = []] = yield all(sagas);

      // put data in redux
      yield put({
        type: types.WATSON_PLAYER_STORE_PUBLIC_PROFILE,
        payload: profile
      });
      // reset orders before storing,
      yield put({ type: types.WATSON_PLAYER_RESET_ORDERS, payload: {} });
      yield put({
        type: types.WATSON_PLAYER_STORE_ADDRESS,
        payload: { address }
      });
      yield put({ type: types.WATSON_PLAYER_STORE_TAGS, payload: { tags } });

      // reset metadata for new player
      yield put({ type: types.WATSON_PLAYER_METADATA_RESET, payload: {} });

      // yield put(push('/watson/home/activity-log'));
      yield put(push(navigateTo));
    } else {
      // player not found
      yield put({ type: types.WATSON_PLAYER_NOT_FOUND, payload: { message } });
    }
  } catch (error) {
    // player not found
    yield put({ type: types.WATSON_PLAYER_NOT_FOUND, payload: { message } });
  }
}

export function* watchGetPlayer(): Generator {
  yield takeLatest(types.WATSON_GET_PLAYER, getPlayer);
}

function* watchPanData(): Generator {
  yield takeEvery(types.WATSON_PLAYER_GET_PAN_DATA, getPanData);
}

function* watchOtherDocumentData(): Generator {
  yield takeEvery(
    types.WATSON_PLAYER_GET_OTHER_DOCUMENT_DATA,
    getOtherDocumentData
  );
}

function* watchKycData(): Generator {
  yield all([watchPanData(), watchOtherDocumentData()]);
}

function* incrementWildUploadLimit(
  action: IncrementWildUploadLimitAction
): Generator {
  const uploadLimits = yield call(api.incrementWildUploadLimit, action.payload);
  yield put(updateWildUploadLimit(uploadLimits as UploadCountInfo));
}

function* watchIncrementWildUploadLimit(): Generator {
  yield takeEvery(
    types.WATSON_PLAYER_INCREMENT_WILD_UPLOAD_LIMIT,
    incrementWildUploadLimit
  );
}

function* watchAcceptKycOrder(): Generator {
  yield takeEvery(
    types.WATSON_PLAYER_ACCEPT_KYC_ORDER,
    updateKycDocumentStatus
  );
}

function* watchRejectKycOrder(): Generator {
  yield takeEvery(
    types.WATSON_PLAYER_REJECT_KYC_ORDER,
    updateKycDocumentStatus
  );
}

function* watchSearchOrder(): Generator {
  yield takeEvery(types.WATSON_PLAYER_METADATA_GET_ORDER, searchOrder);
}

function* watchSyncOrders(): Generator {
  yield takeEvery(types.WATSON_PLAYER_SYNC_ORDERS, syncOrders);
}

function* watchGetOrders(): Generator {
  yield takeEvery(types.WATSON_PLAYER_GET_ORDERS, getOrders);
}

function* watchSyncWallets(): Generator {
  yield takeLatest(types.WATSON_PLAYER_SYNC_WALLETS, syncWallets);
}

function* watchSyncPasses(): Generator {
  yield takeLatest(types.WATSON_PLAYER_SYNC_PASSES, syncPasses);
}

function* watchSyncTimeline(): Generator {
  yield takeLatest(types.WATSON_PLAYER_SYNC_TIMELINE, syncTimeline);
}

function* watchSyncKycOrders(): Generator {
  yield takeLatest(types.WATSON_PLAYER_SYNC_KYC_ORDERS, syncKycOrders);
}

export default function* watchPlayer(): Generator {
  yield all([
    watchGetPlayer(),
    watchKycData(),
    watchIncrementWildUploadLimit(),
    watchSearchOrder(),
    watchGetOrders(),
    watchAcceptKycOrder(),
    watchRejectKycOrder(),
    watchSyncOrders(),
    watchSyncWallets(),
    watchSyncPasses(),
    watchSyncTimeline(),
    watchSyncKycOrders()
  ]);
}
