import {
  cancel,
  cancelled,
  put,
  takeEvery,
  all,
  call,
  take,
  fork,
  delay,
  select
} from 'redux-saga/effects';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import { push } from 'connected-react-router';

import rsf from '../../firestore';
import {
  LOGIN_REQUEST,
  LOGOUT_REQUEST,
  SET_ACCESS_TOKEN
} from '../../reducers/auth/ActionTypes';
import * as actions from '../../reducers/auth/actions';
import { AppState } from '../../reducers';
import { RefreshAccessTokenResponse } from '../../reducers/auth/types';

const authProvider = new firebase.auth.GoogleAuthProvider();

export function* logoutSaga(): Generator {
  try {
    yield call(rsf.auth.signOut);
    yield put(actions.logoutSuccess());
    yield put(push('/login'));
  } catch (error) {
    yield put(actions.logoutFailure(error));
  }
}

export function* getUser(idToken: string): Generator<object, void, any> {
  let credential;
  try {
    credential = firebase.auth.GoogleAuthProvider.credential(idToken);

    const user = yield call(rsf.auth.signInWithCredential, credential);

    // cancel login if email id is not from getmega.com
    if (user.additionalUserInfo.profile.hd !== 'getmega.com') {
      yield cancel(user);
    }

    yield put(actions.loginSuccess(user.user));
    yield put(push('/'));
  } catch (error) {
    yield put(actions.loginFailure(error));
  } finally {
    if (yield cancelled()) {
      yield call(logoutSaga);
    }
  }
}

export function* getIdToken(provider: {
  providerId: string;
}): Generator<object, void, any> {
  try {
    const data = yield call(rsf.auth.signInWithPopup, provider);
    yield call(getUser, data.idToken);
  } catch (error) {
    yield put(actions.loginFailure(error));
  } finally {
    if (yield cancelled()) {
      yield call(logoutSaga);
    }
  }
}

function* loginSaga(): Generator {
  yield fork(getIdToken, authProvider);
}

/**
 * check if user is logged in when reloading page
 */
export function* checkIfLoggedInSaga(): Generator<object, void, any> {
  const channel = yield call(rsf.auth.channel);

  while (true) {
    const { user } = yield take(channel);

    if (user) {
      yield put(actions.loginSuccess(user));
    } else {
      // token not available/expired, need to login again
      yield put(actions.loginFailure(new Error()));
    }
  }
}

function* watchLogin(): Generator {
  yield takeEvery(LOGIN_REQUEST, loginSaga);
}

function* watchLogout(): Generator {
  yield takeEvery(LOGOUT_REQUEST, logoutSaga);
}

// access token expires after 60 minutes
// so refresh it after 59 minutes
// firebase refreshes it
// so firestore calls will work fine
// but its not updated in redux
// so console api calls will start failing
function* watchRefreshAccessToken(): Generator {
  while (true) {
    yield delay(59 * 60 * 1000);

    const refreshToken = yield select(
      (state: AppState) => state.auth.refreshToken
    );

    const rsp = yield fetch(
      `https://securetoken.googleapis.com/v1/token?key=${process.env.REACT_APP_FIREBASE_API_KEY}`,
      {
        method: 'POST',
        body: JSON.stringify({
          refresh_token: refreshToken,
          grant_type: 'refresh_token'
        })
      }
    )
      .then(response => response.json())
      .then(data => {
        return data;
      })
      .catch(() => {
        return {};
      });

    const data: RefreshAccessTokenResponse = rsp as RefreshAccessTokenResponse;
    if (data.access_token) {
      yield put({
        type: SET_ACCESS_TOKEN,
        payload: { accessToken: data.access_token }
      });
    }
  }
}

export default function* authSaga(): Generator {
  yield fork(checkIfLoggedInSaga);
  yield all([watchLogin(), watchLogout(), watchRefreshAccessToken()]);
}
