import { WATSON_PLAYER_STORE_ADDRESS } from './ActionTypes';
import { Address, AddressAction } from './address.types';

const initialState: Address[] = [];

export default function(
  state: Address[] = initialState,
  action: AddressAction
): Address[] {
  const { type, payload } = action;

  switch (type) {
    case WATSON_PLAYER_STORE_ADDRESS:
      return payload.address;

    default:
      return state;
  }
}
