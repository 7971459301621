import {
  all,
  take,
  fork,
  cancel,
  takeLatest,
  call,
  put,
  select
} from 'redux-saga/effects';
import firebase from 'firebase/app';
import { DocumentData, QuerySnapshot } from '@firebase/firestore-types';

import { Task } from 'redux-saga';
import {
  KycOrder,
  KycAction,
  SearchOrderAction
} from '../../../reducers/watson/kyc/types';
import rsf from '../../../firestore';

import * as types from '../../../reducers/watson/kyc/ActionTypes';
import {
  storeAcceptedKycOrders,
  storePendingKycOrders,
  storeRejectedKycOrders,
  storePlayersOrders
} from '../../../reducers/watson/kyc/actions';
import formatOrder from '../../../lib/format-watson-order';
import { AppState } from '../../../reducers';

const formatKycOrders = (actionCreator: (o: KycOrder[]) => KycAction) => (
  snapshot: QuerySnapshot
): KycAction => {
  const kycOrders: KycOrder[] = [];

  snapshot.forEach((doc: DocumentData) => {
    const order = formatOrder(doc);
    kycOrders.push(order as KycOrder);
  });

  return actionCreator(kycOrders);
};

function* syncPendingOrders(): Generator {
  const query = firebase
    .firestore()
    .collection('central_orders')
    .where('Category', '==', 'Kyc')
    .where('State', '==', 'Manual_Supervision')
    .orderBy('CreatedAt', 'desc');

  // eslint-disable-next-line
  // @ts-ignore
  const task: Task = yield fork(rsf.firestore.syncCollection, query, {
    successActionCreator: formatKycOrders(storePendingKycOrders)
  });

  yield take(types.WATSON_KYC_CANCEL_PENDING_ORDERS_SYNC);
  yield cancel(task);
}

function* watchPendingOrdersSync(): Generator {
  yield takeLatest(types.WATSON_KYC_SYNC_PENDING_ORDERS, syncPendingOrders);
}

function* syncAcceptedKycOrders(): Generator {
  const accepted = yield select(
    (state: AppState) => state.watson.kyc.syncedOrdersCount.accepted
  );

  const query = firebase
    .firestore()
    .collection('central_orders')
    .where('Category', '==', 'Kyc')
    .where('State', 'in', ['Complete'])
    .orderBy('CreatedAt', 'desc')
    .limit(accepted as number);

  // eslint-disable-next-line
  // @ts-ignore
  const task: Task = yield fork(rsf.firestore.syncCollection, query, {
    successActionCreator: formatKycOrders(storeAcceptedKycOrders)
  });

  yield take(types.WATSON_KYC_CANCEL_ACCEPTED_ORDERS_SYNC);
  yield cancel(task);
}

function* watchAcceptedOrdersSync(): Generator {
  yield takeLatest(
    types.WATSON_KYC_SYNC_ACCEPTED_ORDERS,
    syncAcceptedKycOrders
  );
}

function* syncRejectedKycOrders(): Generator {
  const rejected = yield select(
    (state: AppState) => state.watson.kyc.syncedOrdersCount.rejected
  );

  const query = firebase
    .firestore()
    .collection('central_orders')
    .where('Category', '==', 'Kyc')
    .where('State', 'in', ['Failed'])
    .orderBy('CreatedAt', 'desc')
    .limit(rejected as number);

  // eslint-disable-next-line
  // @ts-ignore
  const task: Task = yield fork(rsf.firestore.syncCollection, query, {
    successActionCreator: formatKycOrders(storeRejectedKycOrders)
  });

  yield take(types.WATSON_KYC_CANCEL_REJECTED_ORDERS_SYNC);
  yield cancel(task);
}

function* watchRejectedOrdersSync(): Generator {
  yield takeLatest(
    types.WATSON_KYC_SYNC_REJECTED_ORDERS,
    syncRejectedKycOrders
  );
}

function* searchPlayersOrders(action: KycAction): Generator {
  try {
    const { playerId } = action.payload as SearchOrderAction;
    yield put({
      type: types.WATSON_KYC_SET_SEARCHED_PLAYER_ID,
      payload: {
        playerId
      }
    });

    const query = firebase
      .firestore()
      .collection('central_orders')
      .where('Category', '==', 'Kyc')
      .where('PlayerId', '==', playerId)
      .where('State', 'in', ['Processing', 'Complete', 'Failed'])
      .orderBy('CreatedAt', 'desc');

    // eslint-disable-next-line
    // @ts-ignore
    const snapshot = yield call(rsf.firestore.getCollection, query);

    yield put(formatKycOrders(storePlayersOrders)(snapshot as QuerySnapshot));

    yield put({
      type: types.WATSON_KYC_SET_PLAYERS_ORDERS_FOUND,
      payload: {
        playersOrdersFound: true
      }
    });
  } catch (error) {
    yield put({
      type: types.WATSON_KYC_SET_PLAYERS_ORDERS_FOUND,
      payload: {
        orderFound: false
      }
    });
  }
}

function* watchSearchPlayersOrders(): Generator {
  yield takeLatest(types.WATSON_KYC_SEARCH_PLAYERS_ORDERS, searchPlayersOrders);
}

export default function* watchKyc(): Generator {
  yield all([
    watchPendingOrdersSync(),
    watchAcceptedOrdersSync(),
    watchRejectedOrdersSync(),
    watchSearchPlayersOrders()
  ]);
}
