import React, { KeyboardEvent } from 'react';
import { NavbarGroup, Alignment } from '@blueprintjs/core';
import { useHistory } from 'react-router';

import styles from './MegaNavbar.module.scss';

export interface MegaMenuItem {
  title: string;
  route: string;
  isSelected(path: string): boolean;
}

interface LeftMenuProps {
  menuItems: MegaMenuItem[];
}

const LeftMenu: React.FC<LeftMenuProps> = props => {
  const { menuItems } = props;
  const history = useHistory();
  const { pathname } = history.location;

  return (
    <>
      {menuItems.length > 0 && (
        <NavbarGroup align={Alignment.LEFT} className={styles['navbar-items']}>
          {menuItems.map(mi => {
            return (
              <div
                key={mi.title}
                className={[
                  styles['navbar-menu-item'],
                  mi.isSelected(pathname) ? styles.selected : ''
                ].join(' ')}
                role="button"
                tabIndex={0}
                onClick={(): void => history.push(mi.route)}
                onKeyDown={(e: KeyboardEvent<HTMLElement>): void => {
                  if (e.key === 'Enter') {
                    history.push(mi.route);
                  }
                }}
              >
                {mi.title}
              </div>
            );
          })}
        </NavbarGroup>
      )}
    </>
  );
};

export default LeftMenu;
