import React, { useState, useEffect, KeyboardEvent, useCallback } from 'react';
import { InputGroup, Button } from '@blueprintjs/core';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { push } from 'connected-react-router';
import { useHistory } from 'react-router';
import { createSelector } from 'reselect';

import styles from './PlayerProfile.module.scss';

import PlayerCard from './PlayerCard';
import InfoCard from '../../InfoCard/InfoCard';
import facebook from '../../../../assets/facebook.svg';
import { AppState } from '../../../../reducers';
import FlagPlayer from './FlagPlayer';
import BlockPlayer from './BlockPlayer';
import StatusPanel from './StatusPanel';
import api from '../../../../sagas/watson/player/api';

import {
  WATSON_PLAYER_METADATA_GET_ORDER,
  WATSON_PLAYER_SYNC_WALLETS,
  WATSON_PLAYER_CANCEL_WALLETS_SYNC
} from '../../../../reducers/watson/player/ActionTypes';
import MegaToaster from '../../../../components/MegaToaster/MegaToaster';
import { getOtherDocumentData } from '../../../../reducers/watson/player/actions';

const selectPlayer = createSelector(
  (state: AppState) => state.watson.player,
  player => player
);
const selectKycData = createSelector(
  (state: AppState) => state.watson.player.kycData,
  kycData => kycData
);
const selectAuthToken = createSelector(
  (state: AppState) => state.auth.accessToken,
  accessToken => accessToken
);

const PlayerProfile: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    profile,
    wallets,
    metadata,
    orders,
    kycOrders,
    address,
    tags
  } = useSelector(selectPlayer, shallowEqual);
  const playerId = profile.PlayerId;
  const { Deposit, Winnings } = wallets;

  const [isFlagged, setIsFlagged] = useState(false);
  const [isBlocked, setIsBlocked] = useState(false);
  const [isFlaggedReasonsDialogOpen, setIsFlaggedReasonsDialogOpen] = useState(
    false
  );
  const [isBlockedReasonsDialogOpen, setIsBlockedReasonsDialogOpen] = useState(
    false
  );
  const { panData, otherDocumentData, selectedOrderId } = useSelector(
    selectKycData,
    shallowEqual
  );
  const isKycPending =
    panData.document_status !== 'PASSED' ||
    otherDocumentData.document_status !== 'PASSED';
  const authToken = useSelector(selectAuthToken, shallowEqual);

  const flag = useCallback((): void => setIsFlagged(true), []);
  const unflag = useCallback((): void => setIsFlagged(false), []);
  const closeFlaggedReasonsDialog = useCallback((): void => {
    setIsFlaggedReasonsDialogOpen(false);
  }, []);
  const openFlaggedReasonsDialog = useCallback((): void => {
    setIsFlaggedReasonsDialogOpen(true);
  }, []);

  const block = useCallback(async (): Promise<void> => {
    setIsBlocked(true);
    // dispatch(blockPlayer({ playerId, authToken }));
    await api.blockPlayer({ playerId, authToken });
  }, [playerId, authToken]);
  const unblock = useCallback(async (): Promise<void> => {
    setIsBlocked(false);
    // dispatch(unblockPlayer({ playerId, authToken }));
    await api.unblockPlayer({ playerId, authToken });
  }, [playerId, authToken]);
  const closeBlockedReasonsDialog = useCallback((): void => {
    setIsBlockedReasonsDialogOpen(false);
  }, []);
  const openBlockedReasonsDialog = useCallback((): void => {
    setIsBlockedReasonsDialogOpen(true);
  }, []);

  const searchOrder = useCallback(
    (e: KeyboardEvent<HTMLInputElement>): void => {
      if (e.key === 'Enter') {
        dispatch({
          type: WATSON_PLAYER_METADATA_GET_ORDER,
          payload: { orderId: e.currentTarget.value }
        });
      }
    },
    [dispatch]
  );

  useEffect(() => {
    if (metadata.searchedOrderId && !metadata.searchingOrder) {
      if (metadata.orderNotFound) {
        MegaToaster.show({
          message: `Order ${metadata.searchedOrderId} not found!`,
          intent: 'danger'
        });
      } else {
        const searchedOrder = orders[metadata.searchedOrderId];

        if (searchedOrder) {
          const categoryRouteMap: { [index: string]: string } = {
            Purchase: 'gem-transactions',
            Withdrawal: 'withdrawals',
            Winning: 'winnings',
            Reward: 'rewards'
          };
          const category =
            searchedOrder && categoryRouteMap[searchedOrder.Category];
          const nextRoute = `/watson/home/${category}`;

          if (history.location.pathname !== nextRoute) {
            dispatch(push(nextRoute));
          }
        }
      }
    }
  }, [metadata, orders, dispatch, history]);

  useEffect(() => {
    // sync players wallets
    if (playerId) {
      dispatch({
        type: WATSON_PLAYER_SYNC_WALLETS,
        payload: { playerId }
      });
    }

    return (): void => {
      // cancel wallets sync on unmount
      dispatch({
        type: WATSON_PLAYER_CANCEL_WALLETS_SYNC,
        payload: {}
      });
    };
  }, [dispatch, playerId]);

  // get pan data from console api
  useEffect(() => {
    const panId = Object.keys(kycOrders).reduce((id, currentId): string => {
      if (id) return id;

      if (
        selectedOrderId &&
        kycOrders[selectedOrderId].Product.KycDocumentType === 'PAN'
      ) {
        return selectedOrderId;
      }

      return kycOrders[currentId].Product.KycDocumentType === 'PAN'
        ? currentId
        : '';
    }, '');

    if (panId) {
      dispatch(
        getOtherDocumentData({
          orderId: panId,
          authToken,
          playerId
        })
      );
    }
  }, [kycOrders, dispatch, authToken, playerId, selectedOrderId]);

  useEffect(() => {
    const otherId = Object.keys(kycOrders).reduce((id, currentId): string => {
      if (id) return id;

      if (
        selectedOrderId &&
        kycOrders[selectedOrderId].Product.KycDocumentType !== 'PAN'
      )
        return selectedOrderId;

      return kycOrders[currentId].Product.KycDocumentType !== 'PAN'
        ? currentId
        : '';
    }, '');

    if (otherId) {
      dispatch(
        getOtherDocumentData({
          orderId: otherId,
          authToken,
          playerId
        })
      );
    }
  }, [kycOrders, dispatch, authToken, playerId, selectedOrderId]);

  return (
    <>
      <StatusPanel
        isFlagged={isFlagged}
        isBlocked={isBlocked}
        isKycPending={isKycPending}
        openFlaggedReasonsDialog={openFlaggedReasonsDialog}
        openBlockedReasonsDialog={openBlockedReasonsDialog}
        unflag={unflag}
        unblock={unblock}
      />

      <div className={styles['player-profile']}>
        <div className={styles.header}>
          {!profile.fromKycRoute && (
            <div className={styles.title}>Player Profile</div>
          )}
          {profile.fromKycRoute && (
            <Button
              minimal
              intent="primary"
              icon="circle-arrow-left"
              className={styles['back-to-kyc']}
              onClick={(): void => history.goBack()}
            >
              Back to KYC
            </Button>
          )}
          <InputGroup
            className={styles['search-input']}
            placeholder="Search using order number"
            type="search"
            leftIcon="search"
            large
            onKeyDown={searchOrder}
          />
        </div>

        <div className={styles.player}>
          <PlayerCard profile={profile} address={address} tags={tags} />

          <InfoCard
            title="DEPOSIT"
            value={`₹ ${(Deposit && Deposit.Balance) || 0}`}
            width="200px"
            height="100px"
          />
          <InfoCard
            title="WINNINGS"
            value={`₹ ${(Winnings && Winnings.Balance) || 0}`}
            width="200px"
            height="100px"
          />

          <img src={facebook} alt="facebook profile url" />

          <FlagPlayer
            isFlagged={isFlagged}
            isBlocked={isBlocked}
            flag={flag}
            isFlaggedReasonsDialogOpen={isFlaggedReasonsDialogOpen}
            closeFlaggedReasonsDialog={closeFlaggedReasonsDialog}
          />

          <BlockPlayer
            isBlocked={isBlocked}
            unflag={unflag}
            block={block}
            isBlockedReasonsDialogOpen={isBlockedReasonsDialogOpen}
            closeBlockedReasonsDialog={closeBlockedReasonsDialog}
          />
        </div>
      </div>
    </>
  );
};

export default React.memo(PlayerProfile);
