import React from 'react';
import MegaGrid, { CellRenderer } from '../../../components/MegaGrid/MegaGrid';
import { Order } from '../../../reducers/watson/player/orders.types';
import DetailsCard from '../DetailsCard/DetailsCard';
import Loading from '../../../components/Loading/Loading';

export interface PlayerOrderViewProps {
  data: Order[];
  keyMap: { [index: string]: string };
  detailsKeymap: { [index: string]: string };
  selectedRow: Order | undefined;
  isLastPage: boolean;
  isFetchingOrders: boolean;
  selectRow(e: React.MouseEvent, sr: Order): void;
  setSelectedRow(sr: Order | undefined): void;
  onPreviousClick(rowData: Order): void;
  onNextClick(rowData: Order): void;
  detailsTitle: string;
  cellRenderer?: CellRenderer;
}

const PlayerOrderView: React.FC<PlayerOrderViewProps> = props => {
  const {
    data,
    keyMap,
    detailsKeymap,
    selectedRow,
    isLastPage,
    isFetchingOrders,
    selectRow,
    setSelectedRow,
    onNextClick,
    onPreviousClick,
    detailsTitle,
    cellRenderer
  } = props;

  return (
    <div>
      <MegaGrid<Order>
        data={data}
        keyMap={keyMap}
        group="formattedCreatedAt"
        onRowClick={selectRow}
        uniqueId="id"
        selectedRow={selectedRow}
        cellRenderer={cellRenderer}
      />
      {isLastPage && <div className="no-more-orders">No more orders !!!</div>}
      {selectedRow && (
        <DetailsCard
          title={detailsTitle}
          data={selectedRow}
          keyMap={detailsKeymap}
          onClose={(): void => setSelectedRow(undefined)}
          onNextClick={onNextClick}
          onPreviousClick={onPreviousClick}
          cellRenderer={cellRenderer}
        />
      )}

      {isFetchingOrders && <Loading message="Fetching more orders !!!" />}
    </div>
  );
};

export default PlayerOrderView;
