export const WATSON_PLAYERS_GET_BLOCKED_AND_FLAGGED =
  '@@watson/@@players/GET_BLOCKED_AND_FLAGGED';
export const WATSON_PLAYERS_STORE_BLOCKED_AND_FLAGGED =
  '@@watson/@@players/STORE_BLOCKED_AND_FLAGGED';

export const WATSON_PLAYERS_STORE_FLAGGED = '@@watson/@@players/STORE_FLAGGED';
export const WATSON_PLAYERS_GET_FLAGGED = '@@watson/@@players/GET_FLAGGED';

export const WATSON_PLAYERS_STORE_BLOCKED = '@@watson/@@players/STORE_BLOCKED';
export const WATSON_PLAYERS_GET_BLOCKED = '@@watson/@@players/GET_BLOCKED';
