import React from 'react';
import { Switch, Route } from 'react-router-dom';

import './Watson.scss';

import SearchPlayer from './components/SearchPlayer/SearchPlayer';
import Home from './components/Home/Home';
import Players from './components/Players/Players';
import Kyc from './components/Kyc/Kyc';

export const navbarMenuItems = [
  {
    title: 'Home',
    route: '/watson/home/search',
    isSelected: (path: string): boolean => path.startsWith('/watson/home')
  },
  {
    title: 'Players',
    route: '/watson/players/flagged',
    isSelected: (path: string): boolean => path.startsWith('/watson/players')
  },
  {
    title: 'KYC',
    route: '/watson/kyc/pending',
    isSelected: (path: string): boolean => path.startsWith('/watson/kyc')
  }
];

const homePath = '/watson/home';
const HomeWrapper: React.FC = () => <Home path={homePath} />;

const playersPath = '/watson/players';
const PlayersWrapper: React.FC = () => <Players path={playersPath} />;

const kycPath = '/watson/kyc';
const KycWrapper: React.FC = () => <Kyc path={kycPath} />;

const Watson: React.FC = () => {
  return (
    <>
      <Switch>
        <Route exact path="/watson/home/search" component={SearchPlayer} />
        <Route path={homePath} component={HomeWrapper} />
        <Route path={playersPath} component={PlayersWrapper} />
        <Route path={kycPath} component={KycWrapper} />
      </Switch>
    </>
  );
};

export default React.memo(Watson);
