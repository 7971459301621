import React from 'react';
import { Icon } from '@blueprintjs/core';

import styles from './PlayerKyc.module.scss';
import { ChecksDetail } from '../../../../reducers/watson/player/kycData.types';

interface CheckStatusProps {
  data: ChecksDetail[];
}

const formatCheckName = (name: string): string => {
  return name.split('_').join(' ');
};

const renderFailureReason = (d: ChecksDetail): React.ReactElement => {
  const fcn = formatCheckName(d.check_name || '');
  return (
    <div key={d.check_name}>
      {/* eslint-disable-next-line */}
      <span className={styles['check-name']}>{fcn}</span>: {d.failing_reason}
    </div>
  );
};

const CheckStatus: React.FC<CheckStatusProps> = props => {
  const { data } = props;
  const failedChecks = data.filter(d => d.status !== 'PASSED');

  return (
    <>
      <div id={styles['check-status']}>
        <div className={styles.text}>CHECK STATUS</div>
        <div className={styles.statuses}>
          {data.map(d => {
            return (
              <div className={styles.status} key={d.check_name}>
                <Icon
                  icon={d.status === 'PASSED' ? 'tick-circle' : 'error'}
                  intent={d.status === 'PASSED' ? 'success' : 'danger'}
                  className={styles['status-icon']}
                />
                {formatCheckName(d.check_name || '')}
              </div>
            );
          })}
        </div>
      </div>

      {failedChecks.length > 0 && (
        <div id={styles['failure-reason']}>
          <div className={styles.text}>FAILURE REASON</div>
          {failedChecks.map(d => {
            return renderFailureReason(d);
          })}
        </div>
      )}
    </>
  );
};

export default CheckStatus;
