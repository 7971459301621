import { DocumentData } from '@firebase/firestore-types';
import moment from 'moment';

export default function formatOrder<T>(doc: DocumentData): T {
  const data = doc.data();
  const timestamp = data.CreatedAt.toDate();

  let Title;
  if (
    data.Product.Type === 'TABLE_TICKET' &&
    data.Title === 'Entry fee for Poker'
  ) {
    Title = 'Buy in for poker';
  }

  return {
    id: doc.id,
    ...data,
    formattedCreatedAt: moment(timestamp).format('DD/MM/YYYY'),
    formattedTime: moment(timestamp).format('hh:mm A'),
    ...(Title && { Title })
  };
}
