import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Query } from '@firebase/firestore-types';
import moment from 'moment';
import { WATSON_PLAYER_GET_ORDERS } from '../../reducers/watson/player/ActionTypes';
import { OrderCategories } from '../../reducers/watson/player/orders.types';

export interface UseFetchMoreOrdersProps {
  isAtBottom: boolean;
  isLastPage: boolean;
  playerId: string;
  query: Query;
  startDate: Date;
  category?: OrderCategories;
}

const useFetchMoreOrders = (props: UseFetchMoreOrdersProps): void => {
  const [isFetchingOrders, setIsFetchingOrders] = useState(false);
  const {
    isAtBottom,
    isLastPage,
    playerId,
    query,
    startDate: sd,
    category
  } = props;
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(sd);

  useEffect(() => {
    if (isAtBottom && !isLastPage && !isFetchingOrders) {
      setIsFetchingOrders(true);
      const newStartDate = new Date(
        moment(startDate)
          .subtract(7, 'days')
          .format()
      );
      setStartDate(newStartDate);
      // fetch more orders
      dispatch({
        type: WATSON_PLAYER_GET_ORDERS,
        payload: {
          playerId,
          query: query.where('CreatedAt', '>', newStartDate),
          category
        }
      });
    } else if (!isAtBottom) {
      setIsFetchingOrders(false);
    }
  }, [
    isAtBottom,
    dispatch,
    playerId,
    isFetchingOrders,
    isLastPage,
    query,
    startDate,
    category
  ]);
};

export default useFetchMoreOrders;
