import * as types from './ActionTypes';
import { Players, PlayersAction } from './types';

const initialState: Players = {
  flagged: [],
  blocked: []
};

export default (state = initialState, action: PlayersAction): Players => {
  const { type, payload } = action;

  switch (type) {
    case types.WATSON_PLAYERS_STORE_BLOCKED_AND_FLAGGED: {
      const { blocked, flagged } = payload;

      return {
        blocked,
        flagged
      };
    }

    case types.WATSON_PLAYERS_STORE_FLAGGED: {
      const { flagged } = payload;

      return {
        ...state,
        flagged
      };
    }

    case types.WATSON_PLAYERS_STORE_BLOCKED: {
      const { blocked } = payload;

      return {
        ...state,
        blocked
      };
    }

    default:
      return state;
  }
};
