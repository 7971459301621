import { call } from 'redux-saga/effects';
import { DocumentData } from '@firebase/firestore-types';

import rsf from '../../../firestore';
import { Address } from '../../../reducers/watson/player/address.types';

export default function* getAddress(
  playerId: string
): Generator<object, Address[], DocumentData> {
  try {
    const snapshot: DocumentData = yield call(
      rsf.firestore.getCollection,
      `players/${playerId}/address`
    );

    const addresses: Address[] = [];
    snapshot.forEach((addr: DocumentData) => {
      const address = { ...addr.data(), id: addr.id };
      addresses.push(address);
    });

    return addresses;
  } catch (error) {
    return [];
  }
}
