import * as types from './ActionTypes';
import { Wallets, WalletsAction } from './wallets.types';

const initialState: Wallets = {};

export default (
  state: Wallets = initialState,
  action: WalletsAction
): Wallets => {
  const { type, payload } = action;

  switch (type) {
    case types.WATSON_PLAYER_STORE_WALLETS:
      return {
        ...state,
        ...payload
      };

    default:
      return state;
  }
};
