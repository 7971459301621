import React, { useState, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { Pass } from '../../../../reducers/watson/player/passes.types';
import { findPreviousIndex, findNextIndex } from '../../../../lib/array';
import { AppState } from '../../../../reducers';
import DetailsCard from '../../DetailsCard/DetailsCard';
import MegaGrid from '../../../../components/MegaGrid/MegaGrid';
import {
  WATSON_PLAYER_SYNC_PASSES,
  WATSON_PLAYER_CANCEL_PASSES_SYNC
} from '../../../../reducers/watson/player/ActionTypes';

const Passes: React.FC = () => {
  const detailsKeyMap = {
    // id: 'ID',
    Category: 'CATEGORY',
    startTime: 'START TIME',
    expiryAt: 'EXPIRY AT',
    MaxEntryFeeValue: 'MaxEntryFeeValue',
    MinEntryFeeValue: 'MinEntryFeeValue',
    PercentRecovery: 'PercentRecovery',
    RedeemedValue: 'RedeemedValue',
    State: 'STATE'
  };
  const { passes, profile } = useSelector(
    (state: AppState) => state.watson.player
  );
  const playerId = profile.PlayerId;
  const data = Object.values(passes);
  const isLastPage = true;
  const dispatch = useDispatch();

  const [selectedRow, setSelectedRow] = useState<Pass | undefined>();

  const selectRow = (e: React.MouseEvent, sr: Pass): void => {
    setSelectedRow(sr);
  };

  const onPreviousClick = (rowData: Pass): void => {
    setSelectedRow(data[findPreviousIndex(data, rowData)]);
  };

  const onNextClick = (rowData: Pass): void => {
    setSelectedRow(data[findNextIndex(data, rowData)]);
  };

  useEffect(() => {
    // sync players passes
    if (playerId) {
      dispatch({
        type: WATSON_PLAYER_SYNC_PASSES,
        payload: { playerId }
      });
    }

    return (): void => {
      // cancel passes sync on unmount
      dispatch({
        type: WATSON_PLAYER_CANCEL_PASSES_SYNC,
        payload: {}
      });
    };
  }, [dispatch, playerId]);

  return (
    <div>
      <MegaGrid<Pass>
        data={data}
        keyMap={{ formattedTime: 'TIME', ...detailsKeyMap }}
        group="formattedCreatedAt"
        onRowClick={selectRow}
        uniqueId="id"
        selectedRow={selectedRow}
      />
      {isLastPage && <div className="no-more-orders">No more orders !!!</div>}
      {selectedRow && (
        <DetailsCard
          title="PASSES"
          data={selectedRow}
          keyMap={detailsKeyMap}
          onClose={(): void => setSelectedRow(undefined)}
          onNextClick={onNextClick}
          onPreviousClick={onPreviousClick}
        />
      )}
    </div>
  );
};

export default Passes;
