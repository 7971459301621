import { takeLatest, all, put } from 'redux-saga/effects';

import * as types from '../../../reducers/watson/blocked-and-flagged-players/ActionTypes';
import * as api from './api';
import { Player } from '../../../reducers/watson/blocked-and-flagged-players/types';

function* getBlockedAndFlaggedPlayers(): Generator<object, void, Player[]> {
  const [blocked = [], flagged = []] = yield all([
    api.getBlockedPlayers(),
    api.getFlaggedPlayers()
  ]);

  yield put({
    type: types.WATSON_PLAYERS_STORE_BLOCKED_AND_FLAGGED,
    payload: { blocked, flagged }
  });
}

export default function* watchBlockedAndFlaggedPlayers(): Generator {
  yield takeLatest(
    types.WATSON_PLAYERS_GET_BLOCKED_AND_FLAGGED,
    getBlockedAndFlaggedPlayers
  );
}
