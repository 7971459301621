import React, { useState } from 'react';
import { Button, Intent } from '@blueprintjs/core';

import styles from './FlagPlayer.module.scss';

import FlagOrBlockPlayer from './FlagOrBlockPlayerDialog';
import DetailsDialog from './DetailsDialog';

export interface FlagPlayerProps {
  isFlagged: boolean;
  isBlocked: boolean;
  flag(): void;
  isFlaggedReasonsDialogOpen: boolean;
  closeFlaggedReasonsDialog(): void;
}

const flaggingReasons: string[] = [
  'Lorem ipsum dadipiscing elit',
  'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
  'Lorem ipsum dolor sit'
];

const FlagPlayer: React.FC<FlagPlayerProps> = props => {
  const {
    // isFlagged,
    // isBlocked,
    flag,
    isFlaggedReasonsDialogOpen,
    closeFlaggedReasonsDialog
  } = props;

  const [isFlaggingDialogOpen, setIsFlaggingDialogOpen] = useState(false);
  const [selectedReasons, setSelectedReasons] = useState<string[]>([]);

  const flagPlayer = (reasons: string[]): void => {
    setIsFlaggingDialogOpen(false);

    if (reasons.length > 0) {
      setSelectedReasons(reasons);
      flag();
    }
  };

  return (
    <div id="flag-player">
      <Button
        icon="flag"
        className={styles.flag}
        onClick={(): void => {
          setIsFlaggingDialogOpen(true);
        }}
        intent={Intent.WARNING}
        // disabled={isFlagged || isBlocked}
        disabled
        title="Flagging player is not supported yet"
      >
        Flag Player
      </Button>

      <FlagOrBlockPlayer
        isOpen={isFlaggingDialogOpen}
        actionType="flag"
        onCta={flagPlayer}
        reasons={flaggingReasons}
        icon="flag"
      />

      <DetailsDialog
        isOpen={isFlaggedReasonsDialogOpen}
        reasons={selectedReasons}
        icon="flag"
        title="Flagged Details"
        onClose={(): void => closeFlaggedReasonsDialog()}
      />
    </div>
  );
};

export default React.memo(FlagPlayer);
