import { MouseEvent, Dispatch } from 'react';
import { getPlayer } from '../../reducers/watson/player/actions';
import { KycOrder } from '../../reducers/watson/kyc/types';
import { GetPlayerAction } from '../../reducers/watson/player/index.types';

const useGetPlayerDetails = (
  dispatch: Dispatch<GetPlayerAction>,
  authToken: string
) => (e: MouseEvent, selectedOrder: KycOrder): void => {
  const navigateTo = '/watson/home/kyc';

  const playerId = selectedOrder.PlayerId;

  dispatch(
    getPlayer({
      playerId,
      authToken,
      navigateTo,
      fromKycRoute: true,
      kycDocOrderId: selectedOrder.id
    })
  );
};

export default useGetPlayerDetails;
