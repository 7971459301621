import * as types from './ActionTypes';
import {
  GetPanDataAction,
  GetPanDataPayload,
  StorePanDataAction,
  KycDocumentData,
  GetOtherDocumentDataPayload,
  GetOtherDocumentDataAction,
  StoreOtherDocumnetDataAction,
  UploadCountInfo,
  UpdateWildUploadLimitsAction,
  UpdateKycOrderAction,
  AcceptRejectActionPayload
} from './kycData.types';
import {
  GetPlayerPayload,
  GetPlayerAction,
  BlockUnblockPlayerAction,
  BlockUnblockPlayerPayload,
  IncrementWildUploadLimitPayload,
  IncrementWildUploadLimitAction
} from './index.types';
import { ProfileAction } from './profile.types';
import { MetadataAction } from './metadata.types';

export const getPlayer = ({
  playerId,
  authToken,
  navigateTo,
  fromKycRoute,
  kycDocOrderId
}: GetPlayerPayload): GetPlayerAction => ({
  type: types.WATSON_GET_PLAYER,
  payload: {
    playerId,
    authToken,
    navigateTo,
    fromKycRoute,
    kycDocOrderId
  }
});

export const getPanData = ({
  playerId,
  authToken
}: GetPanDataPayload): GetPanDataAction => ({
  type: types.WATSON_PLAYER_GET_PAN_DATA,
  payload: {
    playerId,
    authToken
  }
});

export const storePanData = (panData: KycDocumentData): StorePanDataAction => ({
  type: types.WATSON_PLAYER_STORE_PAN_DATA,
  payload: { panData }
});

export const getOtherDocumentData = ({
  orderId,
  authToken,
  playerId
}: GetOtherDocumentDataPayload): GetOtherDocumentDataAction => ({
  type: types.WATSON_PLAYER_GET_OTHER_DOCUMENT_DATA,
  payload: {
    orderId,
    authToken,
    playerId
  }
});

export const storeOtherDocumentData = (
  otherDocumentData: KycDocumentData
): StoreOtherDocumnetDataAction => ({
  type: types.WATSON_PLAYER_STORE_OTHER_DOCUMENT_DATA,
  payload: {
    otherDocumentData
  }
});

export const resetKycDocumentData = (): {
  type: string;
  payload: {};
} => ({
  type: types.WATSON_PLAYER_KYC_RESET_DOCUMENT_DATA,
  payload: {}
});

export const setFromKycRoute = (fromKycRoute: boolean): ProfileAction => ({
  type: types.WATSON_PLAYER_IS_COMING_FROM_KYC_ROUTE,
  payload: {
    fromKycRoute
  }
});

export const blockPlayer = ({
  playerId,
  authToken
}: BlockUnblockPlayerPayload): BlockUnblockPlayerAction => ({
  type: types.WATSON_PLAYER_BLOCK,
  payload: {
    playerId,
    authToken
  }
});

export const unblockPlayer = ({
  playerId,
  authToken
}: BlockUnblockPlayerPayload): BlockUnblockPlayerAction => ({
  type: types.WATSON_PLAYER_UNBLOCK,
  payload: {
    playerId,
    authToken
  }
});

export const incrementWildUploadLimit = ({
  body,
  authToken
}: IncrementWildUploadLimitPayload): IncrementWildUploadLimitAction => ({
  type: types.WATSON_PLAYER_INCREMENT_WILD_UPLOAD_LIMIT,
  payload: {
    body,
    authToken
  }
});

export const updateWildUploadLimit = (
  uploadLimits: UploadCountInfo
): UpdateWildUploadLimitsAction => ({
  type: types.WATSON_PLAYER_UPDATE_WILD_UPLOAD_LIMIT,
  payload: {
    uploadLimits
  }
});

export const acceptKycOrder = ({
  orderId,
  authToken,
  comment,
  documentType,
  userStatusText,
  cardDetails
}: AcceptRejectActionPayload): UpdateKycOrderAction => ({
  type: types.WATSON_PLAYER_ACCEPT_KYC_ORDER,
  payload: {
    orderId,
    authToken,
    comment,
    documentType,
    isVerified: true,
    userStatusText,
    cardDetails
  }
});

export const rejectKycOrder = ({
  orderId,
  authToken,
  comment,
  documentType,
  userStatusText,
  cardDetails
}: AcceptRejectActionPayload): UpdateKycOrderAction => ({
  type: types.WATSON_PLAYER_REJECT_KYC_ORDER,
  payload: {
    orderId,
    authToken,
    comment,
    documentType,
    isVerified: false,
    userStatusText,
    cardDetails
  }
});

export const resetProfile = (): { type: string; payload: {} } => ({
  type: types.WATSON_PLAYER_RESET_PROFILE,
  payload: {}
});

export const setSearchedOrderId = (orderId: string): MetadataAction => ({
  type: types.WATSON_PLAYER_METADATA_SET_SEARCHED_ORDER_ID,
  payload: {
    searchedOrderId: orderId
  }
});

export const orderNotFound = (): MetadataAction => ({
  type: types.WATSON_PLAYER_METADATA_ORDER_NOT_FOUND,
  payload: {}
});

export const orderFound = (): MetadataAction => ({
  type: types.WATSON_PLAYER_METADATA_ORDER_FOUND,
  payload: {}
});

export const resetSearchedOrder = (): MetadataAction => ({
  type: types.WATSON_PLAYER_METADATA_RESET_SEARCHED_ORDER,
  payload: {}
});
