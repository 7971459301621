import React, { useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { push } from 'connected-react-router';
import { createSelector } from 'reselect';

import styles from './Home.module.scss';

import PlayerProfile from './PlayerProfile/PlayerProfile';
import Contests from './Contests/Contests';
import Withdrawals from './Withdrawals/Withdrawals';
import Deposits from './Deposits/Deposits';
import Rewards from './Rewards/Rewards';
import ActivityLog from './ActivityLog/ActivityLog';
import PlayerKyc from './PlayerKyc/PlayerKyc';
import MegaButtonGroup, {
  RouteType
} from '../../../components/MegaButtonGroup/MegaButtonGroup';
import { AppState } from '../../../reducers';
import Passes from './Passes/Passes';

const selectKycData = createSelector(
  (state: AppState) => state.watson.player.kycData,
  kycData => kycData
);
const selectProfile = createSelector(
  (state: AppState) => state.watson.player.profile,
  profile => profile
);

interface HomeProps {
  path: string;
}

const Home: React.FC<HomeProps> = props => {
  const dispatch = useDispatch();
  const { path } = props;
  const profile = useSelector(selectProfile, shallowEqual);
  const { panData, otherDocumentData } = useSelector(
    selectKycData,
    shallowEqual
  );
  const isKycPending =
    panData.document_status !== 'PASSED' ||
    otherDocumentData.document_status !== 'PASSED';

  const routes: RouteType[] = [
    {
      title: 'activity log',
      path: `${path}/activity-log`,
      component: ActivityLog
    },
    {
      title: 'contests',
      path: `${path}/contests`,
      component: Contests
    },
    {
      title: 'withdrawals',
      path: `${path}/withdrawals`,
      component: Withdrawals
    },
    {
      title: 'deposits',
      path: `${path}/deposits`,
      component: Deposits
    },
    {
      title: 'passes',
      path: `${path}/passes`,
      component: Passes
    },
    {
      title: 'rewards',
      path: `${path}/rewards`,
      component: Rewards
    },
    {
      title: 'kyc',
      path: `${path}/kyc`,
      component: PlayerKyc,
      icon: isKycPending ? 'error' : 'tick-circle'
    }
  ];

  const { DisplayName } = profile;
  useEffect(() => {
    // data will not be available when reloading here
    // so go back to search page
    if (!DisplayName) {
      dispatch(push('/watson/home/search'));
    }
  }, [dispatch, DisplayName]);

  return (
    <div className={styles['watson-dashboard']}>
      <PlayerProfile />
      <div className={styles['screen-selector']}>
        <MegaButtonGroup routes={routes} />
      </div>
      <div>
        <Switch>
          {routes.map(route => {
            return (
              <Route
                key={route.path}
                exact
                path={route.path}
                component={route.component}
              />
            );
          })}
        </Switch>
      </div>
    </div>
  );
};

export default React.memo(Home);
