import React from 'react';
import { Dialog, Classes, Icon, Tag } from '@blueprintjs/core';
import PropTypes from 'prop-types';

import styles from './ProfileDialog.module.scss';

// import playerDP from '../../../assets/playerDP.svg';
import { Profile } from '../../../../reducers/watson/player/profile.types';
import { Address } from '../../../../reducers/watson/player/address.types';
import { Tag as TagType } from '../../../../reducers/watson/player/tags.types';
import getPlayerDpUrl from '../../../util/fb';

type ProfileDialogProps = {
  isOpen: boolean;
  onClose(isOpen: boolean): void;
  profile: Profile;
  address: Address[];
  tags: TagType[];
};

const ProfileDialog: React.FC<ProfileDialogProps> = props => {
  const { isOpen, onClose, profile, address, tags } = props;
  const { DisplayName, Phone, Email } = profile;
  const addr = address[0] || {};
  const playerDp =
    profile.PhotoURL && getPlayerDpUrl(profile.PhotoURL as string);

  return (
    <Dialog
      className={styles['profile-dialog']}
      isOpen={isOpen}
      canOutsideClickClose
      canEscapeKeyClose
      onClose={(): void => onClose(false)}
    >
      <div className={Classes.DIALOG_BODY}>
        <div className={styles['player-card']}>
          <div className={styles['player-dp']}>
            {playerDp ? (
              <img
                src={playerDp}
                alt="player dp"
                className={styles['player-dp-img']}
              />
            ) : (
              <Icon icon="user" iconSize={60} />
            )}
            {/* <Icon icon="user" iconSize={60} /> */}
          </div>
          <div className={styles['player-details']}>
            <div>
              {/* <img src={verifiedBadge} alt="verified" /> */}
              <span className={styles.name}>{DisplayName}</span>
            </div>
            <div className={styles['player-id']}>
              {/* Last played on DD/MM/YYYY at HH:MM */}
              {profile.PlayerId}
            </div>
          </div>
        </div>

        <div className={styles.location}>
          <Icon icon="map-marker" className={styles.icon} />
          <span className={styles['text-span']}>
            {(addr.State && `${addr.State}, ${addr.Country}`) ||
              'Address not found'}
          </span>
        </div>

        <div className={styles.email}>
          <Icon icon="envelope" className={styles.icon} />
          <span className={styles['text-span']}>{Email || '-'}</span>
        </div>

        <div className={styles['android-version']}>
          <Icon icon="mobile-phone" className={styles.icon} />
          <span className={styles['text-span']}>
            {/* {(Phone && Phone.replace(/^\+91/, '+91 ')) || '-'} */}
            {Phone || '-'}
          </span>
        </div>

        <div className={styles.tags}>
          <div className={styles['text-div']}>
            <Icon icon="tag" className={styles.icon} />
            <span className={styles['text-span']}>
              TAGS&nbsp;
              {`(${tags.length})`}
            </span>
          </div>
          <div className={styles['tags-list']}>
            {tags.map((tag: TagType) => {
              return (
                <Tag minimal key={tag.DisplayName} className={styles.tag}>
                  {tag.DisplayName}
                </Tag>
              );
            })}
          </div>
        </div>
      </div>
    </Dialog>
  );
};

ProfileDialog.propTypes = {
  onClose: PropTypes.func.isRequired
};

export default React.memo(ProfileDialog);
