import React, { useMemo, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import firebase from 'firebase/app';
import moment from 'moment';

import styles from './Contests.module.scss';

import useDetectScrollBottom from '../../../../hooks/useDetectScrollBottom';
import useSyncOrders from '../../../hooks/useSyncOrders';
import useFetchMoreOrders from '../../../hooks/useFetchMoreOrders';
import useSelectSearchedOrder from '../../../hooks/useSelectSearchedOrder';
import useInitializeOrdersComponent from '../../../hooks/useInitializeOrdersComponent';
import PlayerOrderView from '../PlayerOrdersView';
import { Payment } from '../../../../reducers/watson/player/orders.types';
import { AppState } from '../../../../reducers';

const Contests: React.FC = () => {
  const detailsKeyMap = {
    Title: 'TITLE',
    id: 'ORDER ID',
    formattedEntryFee: 'ENTRY FEE',
    formattedFulfillments: 'WINNINGS',
    formattedRefunds: 'REFUNDS',
    State: 'STATE'
  };
  const category = 'Purchase';
  const { isFetchingOrders: ifo } = useSelector(
    (state: AppState) => state.watson.player.metadata
  );

  const {
    playerId,
    selectedRow,
    selectRow,
    setSelectedRow,
    onPreviousClick,
    onNextClick,
    isLastPage,
    // data,
    orders,
    metadata
  } = useInitializeOrdersComponent({
    category
  });

  const replacer = (s: string): string =>
    s
      .replace(/User_Internal_/g, '')
      .replace(/_Internal_Cash/g, '')
      .replace(/_Cash/g, '')
      .replace(/INR/g, '₹');

  const paymentFormatter = (payments: Payment[]): string => {
    let totalAmount = 0;

    const formattedValue = payments.reduce((fef: string, payment, index) => {
      totalAmount += payment.Amount;
      return `${fef}${payment.Source} → ${payment.Amount} ${
        payment.Currency
      } → ${payment.Destination}${index !== payments.length - 1 ? '/' : ''}`;
    }, '');

    return replacer(
      `${
        payments.length > 1
          ? `Total = ${totalAmount} ${payments[0].Currency}/`
          : ''
      }${formattedValue}`
    );
  };

  const paymentRenderer = (s: string): React.ReactElement | null => {
    if (!s) return null;

    const rows = s.split('/');
    return (
      <div className={styles['formatted-payment']}>
        {rows.map(r => {
          return <span key={r}>{r}</span>;
        })}
      </div>
    );
  };

  const contestsData = Object.values(orders)
    .filter(
      order =>
        (order.Category === category &&
          (order.Product.Type === 'ROOM_TICKET' ||
            order.Product.Type === 'TABLE_TICKET')) ||
        order.Category === 'Winning' ||
        order.Category === 'Refund'
    )
    .sort((o1, o2) => (o1.CreatedAt.seconds > o2.CreatedAt.seconds ? -1 : 1))
    .map(order => {
      const newOrder = { ...order };

      if (order.Category === category && order.Payments) {
        newOrder.formattedEntryFee = paymentFormatter(newOrder.Payments);
      }

      if (order.Category === category && order.Refunds) {
        if (newOrder.Refunds) {
          newOrder.formattedRefunds = paymentFormatter(newOrder.Refunds);
        }
      }

      if (newOrder.Fulfillments) {
        const Fulfillments = newOrder.Fulfillments.filter(f => f.Payment).map(
          f => f.Payment
        );
        newOrder.formattedFulfillments = paymentFormatter(Fulfillments);
      }

      return newOrder;
    });

  useSelectSearchedOrder({
    category,
    setSelectedRow,
    metadata,
    orders
  });

  const [startDate, setStartDate] = useState(new Date());
  useEffect(() => {
    setStartDate(
      new Date(
        moment()
          .endOf('day')
          .subtract(7, 'days')
          .format()
      )
    );
  }, []);

  const query = useMemo(
    () =>
      firebase
        .firestore()
        .collection('central_orders')
        .where('PlayerId', '==', playerId)
        .where('Category', '==', category)
        // .where('Product.Type', '==', 'ROOM_TICKET')
        .where('State', 'in', ['Complete', 'Failed', 'Processing', 'Pending'])
        .orderBy('CreatedAt', 'desc'),
    [playerId, category]
  );

  const query2 = useMemo(
    () =>
      firebase
        .firestore()
        .collection('central_orders')
        .where('PlayerId', '==', playerId)
        .where('Category', '==', 'Winning')
        .where('State', 'in', ['Complete', 'Failed', 'Processing', 'Pending'])
        .orderBy('CreatedAt', 'desc'),
    [playerId]
  );

  const query3 = useMemo(
    () =>
      firebase
        .firestore()
        .collection('central_orders')
        .where('PlayerId', '==', playerId)
        .where('Category', '==', 'Refund')
        .where('State', 'in', ['Complete', 'Failed', 'Processing', 'Pending'])
        .orderBy('CreatedAt', 'desc'),
    [playerId]
  );

  useSyncOrders({ playerId, query: query2, startDate });
  useSyncOrders({ playerId, query: query3, startDate });
  useSyncOrders({ playerId, query, startDate });

  const isAtBottom = useDetectScrollBottom();
  useFetchMoreOrders({
    isAtBottom,
    isLastPage,
    playerId,
    query,
    startDate,
    category
  });
  useFetchMoreOrders({
    isAtBottom,
    isLastPage,
    playerId,
    query: query2,
    startDate,
    category: 'Winning'
  });
  useFetchMoreOrders({
    isAtBottom,
    isLastPage,
    playerId,
    query: query3,
    startDate,
    category: 'Refund'
  });
  // const isFetchingOrders =
  //   isFetchingOrders1 && isFetchingOrders2 && isFetchingOrders3;
  const isFetchingOrders = !!(ifo.Purchase || ifo.Winning || ifo.Refund);

  const cellRenderer = {
    formattedEntryFee: paymentRenderer,
    formattedRefunds: paymentRenderer,
    formattedFulfillments: paymentRenderer
  };

  return (
    <PlayerOrderView
      detailsTitle="CONTESTS"
      data={contestsData}
      keyMap={{ formattedTime: 'TIME', ...detailsKeyMap }}
      detailsKeymap={detailsKeyMap}
      isLastPage={isLastPage}
      isFetchingOrders={isFetchingOrders}
      selectedRow={selectedRow}
      selectRow={selectRow}
      setSelectedRow={setSelectedRow}
      onNextClick={onNextClick}
      onPreviousClick={onPreviousClick}
      cellRenderer={cellRenderer}
    />
  );
};

(Contests as any).whyDidYouRender = true;

export default Contests;
