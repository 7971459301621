import {
  AuthAction,
  Auth,
  LoginSuccessPayload,
  LoginLogoutErrorPayload
} from './types';

import * as types from './ActionTypes';

export const initialState: Auth = {
  isLoginInProgress: true,
  isAuthenticated: false,
  accessToken: '',
  refreshToken: '',
  user: {},
  error: {}
};

export default (state: Auth = initialState, action: AuthAction): Auth => {
  const { type, payload } = action;

  switch (type) {
    case types.LOGIN_REQUEST:
      return {
        ...state,
        isLoginInProgress: true
      };

    case types.LOGIN_SUCCESS: {
      const {
        user,
        accessToken,
        refreshToken
      } = payload as LoginSuccessPayload;

      return {
        ...state,
        isLoginInProgress: false,
        isAuthenticated: true,
        user,
        accessToken,
        refreshToken,
        error: {}
      };
    }

    case types.LOGIN_ERROR: {
      const { error } = payload as LoginLogoutErrorPayload;

      return {
        ...state,
        isLoginInProgress: false,
        error
      };
    }

    // case types.LOGOUT_REQUEST:
    case types.LOGOUT_SUCCESS:
      return {
        ...state,
        user: {},
        isAuthenticated: false
      };

    case types.LOGOUT_ERROR: {
      const { error } = payload as LoginLogoutErrorPayload;

      return {
        ...state,
        error
      };
    }

    case types.SET_ACCESS_TOKEN: {
      const { accessToken } = payload as LoginSuccessPayload;

      return {
        ...state,
        accessToken
      };
    }

    default:
      return state;
  }
};
