import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import debounce from 'lodash/debounce';

import MegaGrid from '../../../components/MegaGrid/MegaGrid';
import { AppState } from '../../../reducers';
import { KycOrder } from '../../../reducers/watson/kyc/types';
import {
  syncRejectedKycOrders,
  cancelRejectedKycOrdersSync,
  increaseSyncedOrdersCount as increaseSyncedOrdersCountAction
} from '../../../reducers/watson/kyc/actions';
import useDetectScrollBottom from '../../../hooks/useDetectScrollBottom';
import useGetPlayerDetails from '../../hooks/useGetPlayerDetails';

const Rejected: React.FC = () => {
  const dispatch = useDispatch();
  const { orders, metadata, syncedOrdersCount } = useSelector(
    (state: AppState) => state.watson.kyc
  );
  const rejected = Object.values(orders)
    .filter(
      kycOrder =>
        kycOrder.State === 'Failed' &&
        (metadata.searchedPlayerId
          ? metadata.searchedPlayerId === kycOrder.PlayerId
          : true)
    )
    .sort((o1, o2) => (o1.CreatedAt.seconds > o2.CreatedAt.seconds ? -1 : 1));

  const authToken = useSelector((state: AppState) => state.auth.accessToken);
  const isAtBottom = useDetectScrollBottom();

  const keyMap = {
    formattedTime: 'TIME',
    id: 'ORDER ID',
    PlayerId: 'PLAYER ID',
    'Product.KycDocumentType': 'DOCUMENT TYPE'
  };

  const getPlayerDetails = useGetPlayerDetails(dispatch, authToken);

  const increaseSyncedOrdersCount = debounce(() => {
    dispatch(
      increaseSyncedOrdersCountAction({
        rejected: syncedOrdersCount.rejected + 20
      })
    );
    dispatch(cancelRejectedKycOrdersSync());
    dispatch(syncRejectedKycOrders());
  }, 3000);

  const [increasing, setIncreasing] = useState(false);
  if (isAtBottom && !increasing) {
    setIncreasing(true);
    increaseSyncedOrdersCount();
  }

  if (!isAtBottom && increasing) setIncreasing(false);

  return (
    <div id="rejected">
      <MegaGrid<KycOrder>
        data={rejected}
        keyMap={keyMap}
        group="formattedCreatedAt"
        onRowClick={getPlayerDetails}
        uniqueId="id"
      />
    </div>
  );
};

export default Rejected;
