import { WATSON_PLAYER_STORE_TAGS } from './ActionTypes';
import { Tag, TagsAction } from './tags.types';

const initialState: Tag[] = [];

export default function(
  state: Tag[] = initialState,
  action: TagsAction
): Tag[] {
  const { type, payload } = action;

  switch (type) {
    case WATSON_PLAYER_STORE_TAGS:
      return payload.tags;

    default:
      return state;
  }
}
