import { call, all } from 'redux-saga/effects';
import { DocumentData } from '@firebase/firestore-types';

import rsf from '../../../firestore';
import { Tag } from '../../../reducers/watson/player/tags.types';
import { Profile } from '../../../reducers/watson/player/profile.types';

function* getTag(tagId: string): Generator<object, Tag, DocumentData> {
  const snapshot: DocumentData = yield call(
    rsf.firestore.getDocument,
    `tags/${tagId}`
  );

  return snapshot.data();
}

export default function* getTags(
  profile: Profile
): Generator<object, Tag[], DocumentData & Tag[]> {
  try {
    const tagIds = profile.TagIds || [];

    const tags = yield all(tagIds.map((tagId: string) => getTag(tagId)));

    return tags;
  } catch (error) {
    return [];
  }
}
