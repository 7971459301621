import { combineReducers, Reducer } from 'redux';
import { connectRouter, RouterState } from 'connected-react-router';
import { History } from 'history';

import auth from './auth';
import { Auth } from './auth/types';
import watson, { Watson } from './watson';

export interface AppState {
  router: RouterState;
  auth: Auth;
  watson: Watson;
}

const createRootReducer = (history: History): Reducer => {
  const router = connectRouter(history);

  const rootReducer: Reducer<AppState> = combineReducers({
    router,
    auth,
    watson
  });

  return rootReducer;
};

export default createRootReducer;
