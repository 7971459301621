import equal from 'deep-equal';

type primitive = number | string | boolean | [] | {};

export const findIndex = <T extends Record<string, primitive>>(
  data: T[],
  rowData: T
): number => {
  let found = false;
  let index = 0;

  for (let i = 0; i < data.length; i += 1) {
    if (equal(data[i], rowData)) {
      index = i;
      found = true;
    }
  }

  return found ? index : -1;
};

export const findNextIndex = <T extends Record<string, primitive>>(
  data: T[],
  rowData: T
): number => {
  const currentIndex = findIndex(data, rowData);
  return (currentIndex + 1) % data.length;
};

export const findPreviousIndex = <T extends Record<string, primitive>>(
  data: T[],
  rowData: T
): number => {
  const currentIndex = findIndex(data, rowData);
  return (currentIndex - 1 + data.length) % data.length;
};
