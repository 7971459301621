import React, { ChangeEvent } from 'react';
import { InputGroup } from '@blueprintjs/core';
import { DateInput, IDateFormatProps } from '@blueprintjs/datetime';
import moment from 'moment';

import styles from './PlayerKyc.module.scss';
import { DetailsByUser as DetailsByUserInterface } from '../../../../reducers/watson/player/kycData.types';

interface DetailsByUserProps {
  data: DetailsByUserInterface;
  handleCardDetailsChange(e: ChangeEvent<HTMLInputElement>): void;
  handleDateChange(d: Date): void;
}

const DetailsByUser: React.FC<DetailsByUserProps> = props => {
  const { data, handleCardDetailsChange, handleDateChange } = props;

  const getMomentFormatter = (format: string): IDateFormatProps => {
    // note that locale argument comes from locale prop and may be undefined
    return {
      formatDate: (date): string => moment(date).format(format),
      parseDate: (str): Date => moment(str, format).toDate()
    };
  };
  const { formatDate, parseDate } = getMomentFormatter('YYYY-MM-DD');

  return (
    <div id={styles['details-by-user']}>
      <div className={styles.text}>DETAILS BY THE USER</div>
      <div className={styles.details}>
        <div className={styles.detail}>
          <span className={styles.label}>name</span>
          {/* <span className={styles.value}>{data.name_on_card}</span> */}
          <InputGroup
            value={data.name_on_card}
            name="name_on_card"
            onChange={handleCardDetailsChange}
          />
        </div>
        <div className={styles.detail}>
          <span className={styles.label}>card number</span>
          {/* <span className={styles.value}>{data.card_number}</span> */}
          <InputGroup
            value={data.card_number}
            name="card_number"
            onChange={handleCardDetailsChange}
          />
        </div>
        <div className={styles.detail}>
          <span className={styles.label}>date of birth</span>
          {/* <span className={styles.value}>{data.dob}</span> */}
          <DateInput
            formatDate={formatDate}
            parseDate={parseDate}
            onChange={handleDateChange}
            value={new Date(data.dob || '')}
            minDate={new Date('1900-01-01')}
          />
        </div>
      </div>
    </div>
  );
};

export default DetailsByUser;
