import { Event, EventsMap, StoreEventAction } from './timeline.types';
import { WATSON_PLAYER_STORE_TIMELINE } from './ActionTypes';

const initialState = {};

export const getMapFromArray = (
  arr: Event[],
  eventsMap: EventsMap = {}
): EventsMap => {
  return arr.reduce((om: EventsMap, item) => {
    const map = om;
    map[item.id] = item;

    return map;
  }, eventsMap);
};

export default function(
  state: EventsMap = initialState,
  action: StoreEventAction
): EventsMap {
  const { type, payload } = action;

  switch (type) {
    case WATSON_PLAYER_STORE_TIMELINE:
      return getMapFromArray(payload.events, {});

    default:
      return state;
  }
}
