import {
  Kyc,
  KycAction,
  KycOrder,
  KycOrdersMap,
  StoreKycOrdersAction,
  SearchOrderAction,
  SetOrderFoundAction,
  IncreaseSyncedOrdersCountPayload
} from './types';
import * as types from './ActionTypes';

const defaultCount = parseInt(
  process.env.REACT_APP_ORDER_PAGINATION_LIMIT || '20',
  10
);

export const initialState: Kyc = {
  orders: {},
  metadata: {
    searchedPlayerId: '',
    searchingPlayersOrders: false,
    playersOrdersFound: false
  },
  syncedOrdersCount: {
    accepted: defaultCount,
    rejected: defaultCount
  }
};

export const getMapFromArray = (
  arr: KycOrder[],
  ordersMap: KycOrdersMap = {}
): KycOrdersMap => {
  return arr.reduce((om: KycOrdersMap, item) => {
    const map = om;
    map[item.id] = item;

    return map;
  }, ordersMap);
};

export default (state: Kyc = initialState, action: KycAction): Kyc => {
  const { type, payload } = action;

  switch (type) {
    case types.WATSON_KYC_STORE_PENDING_ORDERS:
    case types.WATSON_KYC_STORE_ACCEPTED_ORDERS:
    case types.WATSON_KYC_STORE_REJECTED_ORDERS:
    case types.WATSON_KYC_STORE_PLAYERS_ORDERS: {
      const { kycOrders } = payload as StoreKycOrdersAction;
      const { orders } = state;

      return {
        ...state,
        orders: {
          ...orders,
          ...getMapFromArray(kycOrders, orders)
        }
      };
    }

    case types.WATSON_KYC_SET_SEARCHED_PLAYER_ID: {
      const { playerId } = payload as SearchOrderAction;

      return {
        ...state,
        metadata: {
          searchingPlayersOrders: true,
          searchedPlayerId: playerId,
          playersOrdersFound: false
        }
      };
    }

    case types.WATSON_KYC_SET_PLAYERS_ORDERS_FOUND: {
      const { playersOrdersFound } = payload as SetOrderFoundAction;

      return {
        ...state,
        metadata: {
          ...state.metadata,
          searchingPlayersOrders: false,
          playersOrdersFound
        }
      };
    }

    case types.WATSON_KYC_RESET_METADATA:
      return {
        ...state,
        metadata: {
          ...initialState.metadata
        }
      };

    case types.WATSON_KYC_INCREASE_SYNCED_ORDERS_COUNT: {
      const {
        accepted = state.syncedOrdersCount.accepted,
        rejected = state.syncedOrdersCount.rejected
      } = action.payload as IncreaseSyncedOrdersCountPayload;

      return {
        ...state,
        syncedOrdersCount: {
          accepted,
          rejected
        }
      };
    }

    default:
      return state;
  }
};
