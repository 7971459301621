import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Query } from '@firebase/firestore-types';

import {
  WATSON_PLAYER_SYNC_ORDERS,
  WATSON_PLAYER_CANCEL_ORDERS_SYNC
} from '../../reducers/watson/player/ActionTypes';

export interface UseSyncOrdersProps {
  playerId: string;
  query: Query;
  startDate: Date;
}

const useSyncOrders = (props: UseSyncOrdersProps): void => {
  const { playerId, query: q, startDate } = props;
  const dispatch = useDispatch();

  const query = q.where('CreatedAt', '>', startDate);

  useEffect(() => {
    if (playerId) {
      // start order sync on mount
      dispatch({
        type: WATSON_PLAYER_SYNC_ORDERS,
        payload: { playerId, query }
      });
    }

    // cancel order sync on unmount
    return (): void => {
      dispatch({
        type: WATSON_PLAYER_CANCEL_ORDERS_SYNC,
        payload: {}
      });
    };
    // eslint-disable-next-line
  }, [dispatch, playerId, q, startDate]);
};

export default useSyncOrders;
