import firebase from 'firebase/app';
import { take, fork, cancel } from 'redux-saga/effects';
import { QuerySnapshot, DocumentData } from '@firebase/firestore-types';

import rsf from '../../../firestore';
import {
  WATSON_PLAYER_CANCEL_KYC_ORDERS_SYNC,
  WATSON_PLAYER_STORE_KYC_ORDERS
} from '../../../reducers/watson/player/ActionTypes';
import { KycOrder } from '../../../reducers/watson/kyc/types';

export default function* syncKycOrders(action: {
  type: string;
  payload: { playerId: string };
}): Generator {
  const { playerId } = action.payload;

  const query = firebase
    .firestore()
    .collection('central_orders')
    .where('PlayerId', '==', playerId)
    .where('Category', '==', 'Kyc')
    .where('State', 'in', ['Complete', 'Failed', 'Manual_Supervision'])
    .orderBy('CreatedAt', 'desc');

  // eslint-disable-next-line
  // @ts-ignore
  const task: Task = yield fork(rsf.firestore.syncCollection, query, {
    successActionCreator: (snapshot: QuerySnapshot) => {
      const kycOrders: KycOrder[] = [];

      snapshot.forEach((doc: DocumentData) => {
        const data = doc.data();
        data.id = doc.id;
        kycOrders.push(data);
      });

      return {
        type: WATSON_PLAYER_STORE_KYC_ORDERS,
        payload: { kycOrders }
      };
    }
  });

  yield take(WATSON_PLAYER_CANCEL_KYC_ORDERS_SYNC);
  yield cancel(task);
}
