import React from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

import { Button } from '@blueprintjs/core';
import styles from './StatusPanel.module.scss';

export interface StatusPanelProps {
  isFlagged: boolean;
  isBlocked: boolean;
  isKycPending: boolean;

  openFlaggedReasonsDialog(): void;
  openBlockedReasonsDialog(): void;

  unflag(): void;
  unblock(): void;
}

const StatusPanel: React.FC<StatusPanelProps> = props => {
  const {
    isFlagged,
    isBlocked,
    isKycPending,
    openFlaggedReasonsDialog,
    openBlockedReasonsDialog,
    unflag,
    unblock
  } = props;
  const dispatch = useDispatch();

  return (
    <div id={styles['status-panel']}>
      {isFlagged && (
        <div className={[styles['player-status'], styles.flagged].join(' ')}>
          This user is flagged for investigation
          <div className={styles.cta}>
            <div
              className={styles['details-btn']}
              role="button"
              tabIndex={0}
              onClick={(): void => openFlaggedReasonsDialog()}
              onKeyDown={(): void => openFlaggedReasonsDialog()}
            >
              View Details(if any)
            </div>
            <Button
              className={styles['cta-btn']}
              onClick={(): void => unflag()}
            >
              Unflag
            </Button>
          </div>
        </div>
      )}

      {isBlocked && (
        <div className={[styles['player-status'], styles.blocked].join(' ')}>
          This user is blocked
          <div className={styles.cta}>
            <div
              className={styles['details-btn']}
              role="button"
              tabIndex={0}
              onClick={(): void => openBlockedReasonsDialog()}
              onKeyDown={(): void => openBlockedReasonsDialog()}
            >
              View Details
            </div>
            <Button
              className={styles['cta-btn']}
              onClick={(): void => unblock()}
            >
              Unblock
            </Button>
          </div>
        </div>
      )}

      {isKycPending && (
        <div className={[styles['player-status'], styles.kyc].join(' ')}>
          Verify KYC details
          <div className={styles.cta}>
            <Button
              className={styles['cta-btn']}
              onClick={(): void => {
                dispatch(push('/watson/home/kyc'));
              }}
            >
              View KYC
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default React.memo(StatusPanel);
