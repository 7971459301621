import * as types from './ActionTypes';
import { Order, OrdersAction, OrdersMap } from './orders.types';

const initialState: OrdersMap = {};

export const getMapFromArray = (
  arr: Order[],
  ordersMap: OrdersMap = {}
): OrdersMap => {
  return arr.reduce((om: OrdersMap, item) => {
    const map = om;
    map[item.id] = item;

    return map;
  }, ordersMap);
};

export default (state = initialState, action: OrdersAction): OrdersMap => {
  const { type, payload } = action;

  switch (type) {
    case types.PLAYER_STORE_ORDERS:
      return {
        ...state,
        ...getMapFromArray(payload, state)
      };

    case types.WATSON_PLAYER_RESET_ORDERS:
      return {};

    default:
      return state;
  }
};
