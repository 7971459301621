import React from 'react';
import {
  Alignment,
  Navbar,
  NavbarGroup,
  NavbarHeading
} from '@blueprintjs/core';
import { useSelector } from 'react-redux';

import styles from './MegaNavbar.module.scss';

import OmegaLogo from '../../assets/omega.svg';
import { AppState } from '../../reducers';
import RightMenu from './RightMenu';
import LeftMenu, { MegaMenuItem } from './LeftMenu';

export type MegaNavbarProps = {
  menuItems?: MegaMenuItem[];
};

interface LogoProps {
  src: string;
}

const Logo: React.FC<LogoProps> = React.memo(props => {
  const { src } = props;

  return (
    <NavbarGroup align={Alignment.LEFT}>
      <NavbarHeading>
        <img src={src} alt="Omega" />
      </NavbarHeading>
    </NavbarGroup>
  );
});

const MegaNavbar: React.FC<MegaNavbarProps> = props => {
  const { menuItems = [] } = props;
  const { user } = useSelector((state: AppState) => state.auth);

  return (
    <Navbar className={styles['mega-navbar']} fixedToTop>
      <Logo src={OmegaLogo} />
      <LeftMenu menuItems={menuItems} />
      <RightMenu email={user.email} photoURL={user.photoURL} />
    </Navbar>
  );
};

export default React.memo(MegaNavbar);
