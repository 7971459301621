import { combineReducers } from 'redux';

import profile from './profile';
import wallets from './wallets';
import orders from './orders';
import address from './address';
import tags from './tags';
import kycOrders from './kycOrders';
import kycData from './kycData';
import metadata from './metadata';
import passes from './passes';
import timeline from './timeline';

const player = combineReducers({
  profile,
  wallets,
  passes,
  orders,
  address,
  tags,
  kycOrders,
  kycData,
  metadata,
  timeline
});

export default player;

export type Player = ReturnType<typeof player>;
