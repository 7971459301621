import { KycData, KycDataAction } from './kycData.types';
import {
  WATSON_PLAYER_STORE_PAN_DATA,
  WATSON_PLAYER_STORE_OTHER_DOCUMENT_DATA,
  WATSON_PLAYER_UPDATE_WILD_UPLOAD_LIMIT,
  WATSON_PLAYER_KYC_RESET_DOCUMENT_DATA,
  WATSON_PLAYER_KYC_SET_SELECTED_ORDER_ID
} from './ActionTypes';

const initialState: KycData = {
  panData: {},
  otherDocumentData: {}
};

const kycDataReducer = (
  state = initialState,
  action: KycDataAction
): KycData => {
  const { type, payload } = action;

  switch (type) {
    case WATSON_PLAYER_STORE_PAN_DATA: {
      const { panData } = payload;

      return {
        ...state,
        panData
      };
    }

    case WATSON_PLAYER_STORE_OTHER_DOCUMENT_DATA: {
      const { otherDocumentData } = payload;

      return {
        ...state,
        otherDocumentData
      };
    }

    case WATSON_PLAYER_UPDATE_WILD_UPLOAD_LIMIT: {
      const { uploadLimits } = payload;
      const { panData, otherDocumentData } = state;

      return {
        panData: {
          ...panData,
          ...uploadLimits
        },
        otherDocumentData: {
          ...otherDocumentData,
          ...uploadLimits
        }
      };
    }

    case WATSON_PLAYER_KYC_RESET_DOCUMENT_DATA:
      return {
        ...initialState
      };

    case WATSON_PLAYER_KYC_SET_SELECTED_ORDER_ID: {
      const { selectedOrderId } = action.payload;

      return {
        ...state,
        selectedOrderId
      };
    }

    default:
      return state;
  }
};

export default kycDataReducer;
