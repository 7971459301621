import React, { ChangeEvent, useState } from 'react';
import {
  HTMLTable,
  HTMLSelect,
  Dialog,
  Classes,
  Button,
  Icon
} from '@blueprintjs/core';
import { useDispatch } from 'react-redux';

import './UploadCount.module.scss';
import { UploadCounts } from '../../../../reducers/watson/player/kycData.types';
import { incrementWildUploadLimit } from '../../../../reducers/watson/player/actions';

interface UploadCountProps {
  data: UploadCounts;
  documentType: string;
  playerId: string;
  authToken: string;
  isDocumentVerified: boolean;
}

const UploadCount: React.FC<UploadCountProps> = props => {
  const { data, documentType, playerId, authToken, isDocumentVerified } = props;
  const wildUploadIncrementOptions = [0, 1, 2, 3, 4, 5, 6, 7];
  const [isOpen, setIsOpen] = useState(false);
  const [wildUploadIncrement, setWildUploadIncrement] = useState('0');
  const dispatch = useDispatch();

  const openWildUploadLimitConfirmation = (
    e: ChangeEvent<HTMLSelectElement>
  ): void => {
    setWildUploadIncrement(e.currentTarget.value);
    setIsOpen(true);
  };

  const total =
    (parseInt(data.default_uploads_given || '', 10) || 0) +
    (parseInt(data.wild_uploads_given || '', 10) || 0);

  const cancelWildUploadIncrement = (): void => {
    setWildUploadIncrement('0');
    setIsOpen(false);
  };

  const updateWildUploadLimit = (): void => {
    // call console api to update ;imit
    const wui = parseInt(wildUploadIncrement, 10);
    const body = {
      pan: documentType === 'PAN' ? wui : 0,
      other_document: documentType === 'PAN' ? 0 : wui,
      player_id: playerId
    };
    dispatch(incrementWildUploadLimit({ body, authToken }));
    setIsOpen(false);
  };

  return (
    <div id="upload-count">
      <HTMLTable>
        <thead>
          <tr>
            <th>USER UPLOADED COUNT</th>
            <th>DEFAULT UPLOAD LIMIT (A)</th>
            <th>WILD UPLOAD LIMIT (B)</th>
            <th>TOTAL UPLOAD LIMIT (A+B)</th>
            <th>STATUS</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{data.user_upload_count}</td>
            <td>{data.default_uploads_given}</td>
            <td className="increment-wild-upload">
              <span>{data.wild_uploads_given || '0'}</span>
              <HTMLSelect
                options={wildUploadIncrementOptions}
                className="wild-upload-select"
                onChange={openWildUploadLimitConfirmation}
                value={wildUploadIncrement}
              />
            </td>
            <td>{total}</td>
            <td>
              <Icon
                icon={isDocumentVerified ? 'tick-circle' : 'error'}
                intent={isDocumentVerified ? 'success' : 'danger'}
                style={{ marginRight: '4px' }}
              />
              {isDocumentVerified ? 'Verified' : 'Not verified'}
            </td>
          </tr>
        </tbody>
      </HTMLTable>

      <Dialog
        isOpen={isOpen}
        canOutsideClickClose
        onClose={(): void => {
          cancelWildUploadIncrement();
        }}
        style={{ padding: 0 }}
      >
        <div className={Classes.DIALOG_BODY}>
          <span>
            Increment wild upoad limit by&nbsp;
            {wildUploadIncrement}
            &nbsp;?
          </span>
          <div className="wild-upload-action-div">
            <Button
              minimal
              onClick={(): void => {
                cancelWildUploadIncrement();
              }}
            >
              Cancel
            </Button>
            <Button
              minimal
              intent="warning"
              onClick={(): void => updateWildUploadLimit()}
            >
              Update
            </Button>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default UploadCount;
