import * as types from './ActionTypes';
import { KycOrder, KycAction, IncreaseSyncedOrdersCountPayload } from './types';

export const storeAcceptedKycOrders = (kycOrders: KycOrder[]): KycAction => ({
  type: types.WATSON_KYC_STORE_ACCEPTED_ORDERS,
  payload: {
    kycOrders
  }
});

export const storeRejectedKycOrders = (kycOrders: KycOrder[]): KycAction => ({
  type: types.WATSON_KYC_STORE_REJECTED_ORDERS,
  payload: {
    kycOrders
  }
});

export const storePendingKycOrders = (kycOrders: KycOrder[]): KycAction => ({
  type: types.WATSON_KYC_STORE_PENDING_ORDERS,
  payload: {
    kycOrders
  }
});

export const storePlayersOrders = (kycOrders: KycOrder[]): KycAction => ({
  type: types.WATSON_KYC_STORE_PLAYERS_ORDERS,
  payload: {
    kycOrders
  }
});

export const syncPendingKycOrders = (): KycAction => ({
  type: types.WATSON_KYC_SYNC_PENDING_ORDERS,
  payload: {}
});

export const syncAcceptedKycOrders = (): KycAction => ({
  type: types.WATSON_KYC_SYNC_ACCEPTED_ORDERS,
  payload: {}
});

export const syncRejectedKycOrders = (): KycAction => ({
  type: types.WATSON_KYC_SYNC_REJECTED_ORDERS,
  payload: {}
});

export const cancelPendingKycOrdersSync = (): KycAction => ({
  type: types.WATSON_KYC_CANCEL_PENDING_ORDERS_SYNC,
  payload: {}
});

export const cancelAcceptedKycOrdersSync = (): KycAction => ({
  type: types.WATSON_KYC_CANCEL_ACCEPTED_ORDERS_SYNC,
  payload: {}
});

export const cancelRejectedKycOrdersSync = (): KycAction => ({
  type: types.WATSON_KYC_CANCEL_REJECTED_ORDERS_SYNC,
  payload: {}
});

export const increaseSyncedOrdersCount = ({
  accepted,
  rejected
}: IncreaseSyncedOrdersCountPayload): KycAction => ({
  type: types.WATSON_KYC_INCREASE_SYNCED_ORDERS_COUNT,
  payload: { accepted, rejected }
});
