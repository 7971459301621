export const WATSON_SEARCHING_PLAYER = '@@watson/layer/SEARCHING';
export const WATSON_GET_PLAYER = '@@watson/@@player/GET_PLAYER';
export const WATSON_PLAYER_NOT_FOUND = '@@watson/@@player/NOT_FOUND';

export const WATSON_PLAYER_STORE_PUBLIC_PROFILE =
  '@@watson/@@player/@@profile/STORE_PUBLIC_PROFILE';
export const WATSON_PLAYER_RESET_PROFILE =
  '@@watson/@@player/@@profile/RESET_PROFILE';

export const WATSON_PLAYER_STORE_WALLETS =
  '@@watson/@@player/@@wallets/STORE_WALLETS';
export const WATSON_PLAYER_SYNC_WALLETS =
  '@@watson/@@player/@@wallets/SYNC_WALLETS';
export const WATSON_PLAYER_CANCEL_WALLETS_SYNC =
  '@@watson/@@player/@@wallets/CANCEL_WALLETS_SYNC';

export const PLAYER_STORE_ORDERS = '@@watson/@@player/@@orders/STORE_ORDERS';
export const WATSON_PLAYER_RESET_ORDERS =
  '@@watson/@@player/@@orders/RESET_ORDERS';
export const WATSON_PLAYER_SYNC_ORDERS =
  '@@watson/@@player/@@orders/SYNC_ORDERS';
export const WATSON_PLAYER_CANCEL_ORDERS_SYNC =
  '@@watson/@@player/@@orders/CANCEL_ORDERS_SYNC';
export const WATSON_PLAYER_GET_ORDERS = '@@watson/@@player/@@orders/GET_ORDERS';

export const WATSON_PLAYER_STORE_ADDRESS =
  '@@watson/@@player/@@address/STORE_ADDRESS';

export const WATSON_PLAYER_STORE_TAGS = '@@watson/@@player/@@tags/STORE_TAGS';

export const WATSON_PLAYER_STORE_KYC_ORDERS =
  '@@watson/@@player/@@kyc/STORE_KYC_ORDERS';
export const WATSON_PLAYER_SYNC_KYC_ORDERS =
  '@@watson/@@player/@@kyc/SYNC_KYC_ORDERS';
export const WATSON_PLAYER_CANCEL_KYC_ORDERS_SYNC =
  '@@watson/@@player/@@kyc/CANCEL_KYC_ORDERS_SYNC';
export const WATSON_PLAYER_KYC_SET_SELECTED_ORDER_ID =
  '@@watson/@@player/@@kyc/SET_SELECTED_ORDER_ID';

export const WATSON_PLAYER_GET_PAN_DATA =
  '@@watson/@@player/@@kycData/GET_PAN_DATA';
export const WATSON_PLAYER_STORE_PAN_DATA =
  '@@watson/@@player/@@kycData/STORE_PAN_DATA';
export const WATSON_PLAYER_GET_OTHER_DOCUMENT_DATA =
  '@@watson/@@player/@@kyc/GET_OTHER_DOCUMENT_DATA';
export const WATSON_PLAYER_STORE_OTHER_DOCUMENT_DATA =
  '@@watson/@@player/@@kyc/STORE_OTHER_DOCUMENT_DATA';
export const WATSON_PLAYER_KYC_RESET_DOCUMENT_DATA =
  '@@watson/@@player/@@kyc/RESET_DOCUMENT_DATA';

export const WATSON_PLAYER_IS_COMING_FROM_KYC_ROUTE =
  '@@watson/@@player/IS_COMING_FROM_KYC_ROUTE';

export const WATSON_PLAYER_BLOCK = '@@watson/@@player/BLOCK';
export const WATSON_PLAYER_UNBLOCK = '@@watson/@@player/UNBLOCK';

export const WATSON_PLAYER_INCREMENT_WILD_UPLOAD_LIMIT =
  '@@watson/@@player/@@kyc/INCREMENT_WILD_UPLOAD_LIMIT';

export const WATSON_PLAYER_UPDATE_WILD_UPLOAD_LIMIT =
  '@@watson/@@player/@@kyc/UPDATE_WILD_UPLOAD_LIMIT';

export const WATSON_PLAYER_ACCEPT_KYC_ORDER =
  '@@watson/@@player/@@kyc/ACCEPT_KYC_ORDER';
export const WATSON_PLAYER_REJECT_KYC_ORDER =
  '@@watson/@@player/@@kyc/REJECT_KYC_ORDER';

export const WATSON_PLAYER_METADATA_GET_ORDER =
  '@@watson/@@player/@@metadata/GET_ORDER';
export const WATSON_PLAYER_METADATA_SET_SEARCHED_ORDER_ID =
  '@@watson/@@player/@@metadata/SET_SEARCHED_ORDER_ID';
export const WATSON_PLAYER_METADATA_ORDER_NOT_FOUND =
  '@@watson/@@player/@@metadata/ORDER_NOT_FOUND';
export const WATSON_PLAYER_METADATA_ORDER_FOUND =
  '@@watson/@@player/@@metadata/ORDER_FOUND';
export const WATSON_PLAYER_METADATA_RESET_SEARCHED_ORDER =
  '@@watson/@@player/@@metadata/RESET_SEARCHED_ORDER';
export const WATSON_PLAYER_METADATA_SET_IS_LAST_PAGE =
  '@@watson/@@player/@@metadata/SET_IS_LAST_PAGE';
export const WATSON_PLAYER_METADATA_SET_IS_FETCHING_ORDER =
  '@@watson/@@player/@@metadat/SET_IS_FETCHING_ORDER';

export const WATSON_PLAYER_METADATA_RESET =
  '@@watson/@@player/@@metadata/RESET';

export const WATSON_PLAYER_STORE_PASSES =
  '@@watson/@@player/@@passes/STORE_PASSES';
export const WATSON_PLAYER_SYNC_PASSES =
  '@@watson/@@player/@@passes/SYNC_PASSES';
export const WATSON_PLAYER_CANCEL_PASSES_SYNC =
  '@@watson/@@player/@@passes/CANCEL_PASSES_SYNC';

export const WATSON_PLAYER_STORE_TIMELINE =
  '@@watson/@@player/@@passes/STORE_TIMELINE';
export const WATSON_PLAYER_SYNC_TIMELINE =
  '@@watson/@@player/@@passes/SYNC_TIMELINE';
export const WATSON_PLAYER_CANCEL_TIMELINE_SYNC =
  '@@watson/@@player/@@passes/CANCEL_TIMELINE_SYNC';
