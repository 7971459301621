import { Pass, PassesMap, StorePassesAction } from './passes.types';
import { WATSON_PLAYER_STORE_PASSES } from './ActionTypes';

const initialState = {};

export const getMapFromArray = (
  arr: Pass[],
  passesMap: PassesMap = {}
): PassesMap => {
  return arr.reduce((om: PassesMap, item) => {
    const map = om;
    map[item.id] = item;

    return map;
  }, passesMap);
};

export default function(
  state: PassesMap = initialState,
  action: StorePassesAction
): PassesMap {
  const { type, payload } = action;

  switch (type) {
    case WATSON_PLAYER_STORE_PASSES:
      return getMapFromArray(payload.passes, {});

    default:
      return state;
  }
}
