import React, { ReactNode } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector, shallowEqual } from 'react-redux';
import { Spinner } from '@blueprintjs/core';
import { createSelector } from 'reselect';

import styles from './ProtectedRoute.module.scss';

import { AppState } from '../../reducers';

type Props = { component: React.FC; path: string; exact?: boolean };

const selectAuth = createSelector(
  (state: AppState) => state.auth,
  auth => auth
);

const ProtectedRoute: React.FC<Props> = ({ component, path, exact }) => {
  const Component = component;
  const { isLoginInProgress, isAuthenticated } = useSelector(
    selectAuth,
    shallowEqual
  );

  const redirectIfNotAuthenticated = (): ReactNode => {
    return isAuthenticated ? (
      <Component />
    ) : (
      <Redirect to={{ pathname: '/login' }} />
    );
  };

  return (
    <Route
      exact={exact}
      path={path}
      render={(): ReactNode =>
        isLoginInProgress ? (
          <div id={styles.authenticating}>
            <Spinner intent="warning" />
            <div>Authenticating</div>
          </div>
        ) : (
          redirectIfNotAuthenticated()
          // eslint-disable-next-line
        )}
    />
  );
};

// (ProtectedRoute as any).whyDidYouRender = true;

export default ProtectedRoute;
