import { WATSON_PLAYER_STORE_KYC_ORDERS } from './ActionTypes';
import { KycOrdersMap } from '../kyc/types';
import { KycOrdersAction } from './kycOrders.types';
import { getMapFromArray } from '../kyc';

const initialState: KycOrdersMap = {};

export default function kycOrdersReducer(
  state = initialState,
  action: KycOrdersAction
): KycOrdersMap {
  const { type, payload } = action;

  switch (type) {
    case WATSON_PLAYER_STORE_KYC_ORDERS: {
      const { kycOrders } = payload;

      return {
        ...getMapFromArray(kycOrders, {})
      };
    }

    default:
      return state;
  }
}
