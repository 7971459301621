import * as types from './ActionTypes';
import {
  Profile,
  ProfileAction,
  PlayerNotFoundPayload,
  FromKycRoutePayload
} from './profile.types';

const initialState: Profile = {
  PlayerId: '',
  // when player profile is not found
  notFound: false,
  notFoundMessage: null,
  searchingPlayer: false,
  fromKycRoute: false
};

export default (
  state: Profile = initialState,
  action: ProfileAction
): Profile => {
  const { type, payload } = action;

  switch (type) {
    case types.WATSON_SEARCHING_PLAYER:
      return {
        ...state,
        searchingPlayer: true
      };

    case types.WATSON_PLAYER_STORE_PUBLIC_PROFILE:
      return {
        ...state,
        ...payload,
        notFound: false,
        notFoundMessage: null,
        searchingPlayer: false
      };

    case types.WATSON_PLAYER_NOT_FOUND: {
      const { message } = action.payload as PlayerNotFoundPayload;

      return {
        PlayerId: '',
        notFound: true,
        notFoundMessage: message,
        searchingPlayer: false,
        fromKycRoute: false
      };
    }

    case types.WATSON_PLAYER_IS_COMING_FROM_KYC_ROUTE: {
      const { fromKycRoute } = payload as FromKycRoutePayload;

      return {
        ...state,
        fromKycRoute
      };
    }

    case types.WATSON_PLAYER_RESET_PROFILE:
      return {
        ...initialState
      };

    default:
      return state;
  }
};
