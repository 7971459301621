export const WATSON_KYC_STORE_ACCEPTED_ORDERS =
  '@@watson/@@kyc/STORE_ACCEPTED_ORDERS';
export const WATSON_KYC_STORE_REJECTED_ORDERS =
  '@@watson/@@kyc/STORE_REJECTED_ORDERS';
export const WATSON_KYC_STORE_PENDING_ORDERS =
  '@@watson/@@kyc/STORE_PENDING_ORDERS';
export const WATSON_KYC_STORE_PLAYERS_ORDERS =
  '@@watson/kyc/STORE_PLAYERS_ORDERS';

export const WATSON_KYC_SYNC_PENDING_ORDERS =
  '@@watson/@@kyc/SYNC_PENDING_ORDERS';
export const WATSON_KYC_CANCEL_PENDING_ORDERS_SYNC =
  '@@watson/@@kyc/CANCEL_PENDING_ORDERS_SYNC';

export const WATSON_KYC_SYNC_ACCEPTED_ORDERS =
  '@@watson/@@kyc/SYNC_ACCEPTED_ORDERS';
export const WATSON_KYC_CANCEL_ACCEPTED_ORDERS_SYNC =
  '@@watson/@@kyc/CANCEL_ACCEPTED_ORDERS_SYNC';

export const WATSON_KYC_SYNC_REJECTED_ORDERS =
  '@@watson/@@kyc/SYNC_REJECTED_ORDERS';
export const WATSON_KYC_CANCEL_REJECTED_ORDERS_SYNC =
  '@@watson/@@kyc/CANCEL_REJECTED_ORDERS_SYNC';

export const WATSON_KYC_SEARCH_PLAYERS_ORDERS =
  '@@watson/@@kyc/SEARCH_PLAYERS_ORDERS';
export const WATSON_KYC_SET_SEARCHED_PLAYER_ID =
  '@@watson/@@kyc/SET_SEARCHED_PLAYER_ID';
export const WATSON_KYC_SET_PLAYERS_ORDERS_FOUND =
  '@@watson/@@kyc/SET_PLAYERS_ORDERS_FOUND';
export const WATSON_KYC_RESET_METADATA = '@@watson/@@kyc/RESET_METADATA';

export const WATSON_KYC_INCREASE_SYNCED_ORDERS_COUNT =
  '@@watson/@@kyc/INCREASE_SYNCED_ORDERS_COUNT';
