import React, { useMemo, useState, useEffect } from 'react';
import firebase from 'firebase/app';
import moment from 'moment';
// import { useSelector } from 'react-redux';

import useSyncOrders from '../../../hooks/useSyncOrders';
// import useDetectScrollBottom from '../../../../hooks/useDetectScrollBottom';
// import useFetchMoreOrders from '../../../hooks/useFetchMoreOrders';
import useSelectSearchedOrder from '../../../hooks/useSelectSearchedOrder';
import useInitializeOrdersComponent from '../../../hooks/useInitializeOrdersComponent';
import PlayerOrderView from '../PlayerOrdersView';
// import { AppState } from '../../../../reducers';

const Withdrawals: React.FC = () => {
  const detailsKeyMap = {
    id: 'ORDER ID',
    'Product.Price.ListingPrice': 'AMOUNT WITHDRAWN',
    Title: 'TITLE',
    State: 'STATE'
  };
  const category = 'Withdrawal';
  // const { isFetchingOrders: ifo } = useSelector(
  //   (state: AppState) => state.watson.player.metadata
  // );

  const {
    playerId,
    selectedRow,
    selectRow,
    setSelectedRow,
    onPreviousClick,
    onNextClick,
    // isLastPage,
    data,
    orders,
    metadata
  } = useInitializeOrdersComponent({ category });

  useSelectSearchedOrder({
    category,
    setSelectedRow,
    metadata,
    orders
  });

  const [startDate, setStartDate] = useState(new Date());
  useEffect(() => {
    setStartDate(
      new Date(
        moment()
          .endOf('day')
          .subtract(400, 'days')
          .format()
      )
    );
  }, []);

  const query = useMemo(
    () =>
      firebase
        .firestore()
        .collection('central_orders')
        .where('PlayerId', '==', playerId)
        .where('Category', '==', category)
        .where('State', 'in', ['Complete', 'Failed', 'Processing', 'Pending'])
        .orderBy('CreatedAt', 'desc'),
    [playerId, category]
  );
  useSyncOrders({ playerId, query, startDate });

  // const isAtBottom = useDetectScrollBottom();
  // useFetchMoreOrders({
  //   isAtBottom,
  //   isLastPage,
  //   playerId,
  //   query,
  //   startDate,
  //   category
  // });

  // const isFetchingOrders = !!ifo.Withdrawal;

  return (
    <PlayerOrderView
      detailsTitle="WITHDRAWALS"
      data={data}
      keyMap={{ formattedTime: 'TIME', ...detailsKeyMap }}
      detailsKeymap={detailsKeyMap}
      isLastPage
      isFetchingOrders={false}
      selectedRow={selectedRow}
      selectRow={selectRow}
      setSelectedRow={setSelectedRow}
      onNextClick={onNextClick}
      onPreviousClick={onPreviousClick}
    />
  );
};

export default Withdrawals;
