import {
  Metadata,
  MetadataAction,
  SetSearchedOrderIdPayload,
  SetIsFetchingOrdersPayload
} from './metadata.types';
import * as types from './ActionTypes';

const initialState: Metadata = {
  searchingPlayer: false,
  searchedPlayerId: '',
  playerNotFound: false,
  playerNotFoundMessage: '',

  searchingOrder: false,
  searchedOrderId: '',
  orderNotFound: false,

  isLastPage: {
    Purchase: false,
    Withdrawal: false,
    Deposit: false,
    Reward: false,
    Winning: false,
    Refund: false
  },

  isFetchingOrders: {
    Purchase: false,
    Withdrawal: false,
    Reward: false,
    Deposit: false,
    Winning: false,
    Refund: false
  }
};

export default function(
  state = initialState,
  action: MetadataAction
): Metadata {
  const { type, payload } = action;

  switch (type) {
    case types.WATSON_PLAYER_METADATA_SET_SEARCHED_ORDER_ID: {
      const { searchedOrderId } = payload as SetSearchedOrderIdPayload;

      return {
        ...state,
        searchingOrder: true,
        searchedOrderId,
        orderNotFound: false
      };
    }

    case types.WATSON_PLAYER_METADATA_ORDER_NOT_FOUND:
      return {
        ...state,
        orderNotFound: true,
        searchingOrder: false
      };

    case types.WATSON_PLAYER_METADATA_ORDER_FOUND:
      return {
        ...state,
        searchingOrder: false,
        orderNotFound: false
      };

    case types.WATSON_PLAYER_METADATA_RESET_SEARCHED_ORDER:
      return {
        ...state,
        searchedOrderId: ''
      };

    case types.WATSON_PLAYER_METADATA_SET_IS_LAST_PAGE: {
      return {
        ...state,
        isLastPage: {
          ...state.isLastPage,
          ...action.payload
        }
      };
    }

    case types.WATSON_PLAYER_METADATA_SET_IS_FETCHING_ORDER: {
      const { isFetchingOrders } = payload as SetIsFetchingOrdersPayload;

      return {
        ...state,
        isFetchingOrders: {
          ...state.isFetchingOrders,
          ...isFetchingOrders
        }
      };
    }

    case types.WATSON_PLAYER_METADATA_RESET:
      return { ...initialState };

    default:
      return state;
  }
}
