import React, { useState, useEffect, useCallback, ChangeEvent } from 'react';
import { Button, ButtonGroup } from '@blueprintjs/core';
import { useSelector } from 'react-redux';
import moment from 'moment';

import styles from './PlayerKyc.module.scss';
import { AppState } from '../../../../reducers';
import DetailsByUser from './DetailsByUser';
import DetailsByOCR from './DetailsByOCR';
import CheckStatus from './CheckStatus';
import DocumentImages from './DocumentImages';
import UploadCount from './UploadCount';
import AcceptOrReject from './AcceptOrReject';
import KycOrdersList from './KycOrdersList';
import { KycOrder } from '../../../../reducers/watson/kyc/types';
import { DetailsByUser as DetailsByUserType } from '../../../../reducers/watson/player/kycData.types';

const UserKyc: React.FC = () => {
  const [selectedDocument, setSelectedDocument] = useState('PAN');
  const authToken = useSelector((state: AppState) => state.auth.accessToken);
  const { profile, kycOrders, kycData } = useSelector(
    (state: AppState) => state.watson.player
  );
  const playerId = profile.PlayerId;
  const { panData, otherDocumentData, selectedOrderId } = kycData;

  const data = selectedDocument === 'PAN' ? panData : otherDocumentData;
  const filteredKycOrders = Object.values(kycOrders).filter(kycOrder =>
    selectedDocument === 'PAN'
      ? kycOrder.Product.KycDocumentType === 'PAN'
      : kycOrder.Product.KycDocumentType !== 'PAN'
  );

  const getSelectedPanOrder = useCallback(
    (kycOrder: KycOrder): boolean => {
      if (
        selectedOrderId &&
        kycOrders[selectedOrderId].Product.KycDocumentType === 'PAN'
      ) {
        return kycOrder.id === selectedOrderId;
      }

      return kycOrder.Product.KycDocumentType === 'PAN';
    },
    [kycOrders, selectedOrderId]
  );
  const getSelectedOtherOrder = useCallback(
    (kycOrder: KycOrder): boolean => {
      if (
        selectedOrderId &&
        kycOrders[selectedOrderId].Product.KycDocumentType !== 'PAN'
      ) {
        return kycOrder.id === selectedOrderId;
      }

      return kycOrder.Product.KycDocumentType !== 'PAN';
    },
    [kycOrders, selectedOrderId]
  );

  const [selectedPanOrder, setSelectedPanOrder] = useState(
    Object.values(kycOrders).find(kycOrder => getSelectedPanOrder(kycOrder))
  );
  const [selectedOtherOrder, setSelectedOtherOrder] = useState(
    Object.values(kycOrders).find(kycOrder => getSelectedOtherOrder(kycOrder))
  );

  useEffect(() => {
    if (!selectedPanOrder) {
      setSelectedPanOrder(
        Object.values(kycOrders).find(kycOrder => getSelectedPanOrder(kycOrder))
      );
    }
  }, [kycOrders, selectedPanOrder, getSelectedPanOrder]);

  useEffect(() => {
    if (!selectedOtherOrder) {
      setSelectedOtherOrder(
        Object.values(kycOrders).find(kycOrder =>
          getSelectedOtherOrder(kycOrder)
        )
      );
    }
  }, [kycOrders, selectedOtherOrder, getSelectedOtherOrder]);

  useEffect(() => {
    if (selectedOrderId) {
      const selectedOrder = Object.values(kycOrders).find(
        kycOrder => kycOrder.id === selectedOrderId
      );

      setSelectedDocument(
        selectedOrder && selectedOrder.Product.KycDocumentType === 'PAN'
          ? 'PAN'
          : 'Other'
      );
    }
  }, [selectedOrderId, kycOrders]);

  const [cardDetails, setCardDetails] = useState(data.details_by_user);
  useEffect(() => {
    setCardDetails(data.details_by_user);
  }, [data]);

  const handleCardDetailsChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const newCardDetails = {
      ...cardDetails,
      [e.currentTarget.name]: e.currentTarget.value
    };
    setCardDetails(newCardDetails);
  };

  const handleDateChange = (d: Date): void => {
    const newCardDetails = {
      ...cardDetails,
      dob: moment(d).format('YYYY-MM-DD')
    };
    setCardDetails(newCardDetails);
  };

  return (
    <div id={styles['user-kyc']}>
      <ButtonGroup id="kyc-btn-group">
        {panData.document_type && (
          <Button
            // style={{ borderTopLeftRadius: '8px', borderBottomLeftRadius: '8px' }}
            className={[
              'round-left',
              selectedDocument === 'PAN' ? 'selected' : ''
            ].join(' ')}
            onClick={(): void => setSelectedDocument('PAN')}
          >
            PAN
          </Button>
        )}
        {otherDocumentData.document_type && (
          <Button
            className={[
              'round-right',
              selectedDocument !== 'PAN' ? 'selected' : ''
            ].join(' ')}
            onClick={(): void => setSelectedDocument('Other')}
          >
            {otherDocumentData.document_type}
          </Button>
        )}
      </ButtonGroup>

      <KycOrdersList
        kycOrders={filteredKycOrders}
        selectedOrder={
          selectedDocument === 'PAN' ? selectedPanOrder : selectedOtherOrder
        }
        setSelectedOrder={
          selectedDocument === 'PAN'
            ? setSelectedPanOrder
            : setSelectedOtherOrder
        }
        authToken={authToken}
      />

      {data.upload_count_info && (
        <UploadCount
          data={
            selectedDocument === 'PAN'
              ? data.upload_count_info.pan
              : data.upload_count_info.other_documents
          }
          documentType={selectedDocument}
          playerId={playerId}
          authToken={authToken}
          isDocumentVerified={data.document_status === 'PASSED'}
        />
      )}

      {data.file_urls && <DocumentImages data={data.file_urls} />}

      {cardDetails && (
        <DetailsByUser
          data={cardDetails}
          handleCardDetailsChange={handleCardDetailsChange}
          handleDateChange={handleDateChange}
        />
      )}

      {data.details_by_ocr && <DetailsByOCR data={data.details_by_ocr} />}

      {data.checks_details && <CheckStatus data={data.checks_details} />}

      {data.order_id && (
        <AcceptOrReject
          orderId={data.order_id}
          authToken={authToken}
          documentType={selectedDocument}
          cardDetails={cardDetails as DetailsByUserType}
        />
      )}
    </div>
  );
};

export default UserKyc;
