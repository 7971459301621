import React, { useState } from 'react';
import { Icon } from '@blueprintjs/core';

import styles from './PlayerCard.module.scss';

import ProfileDialog from './ProfileDialog';
import getPlayerDpUrl from '../../../util/fb';
import { Profile } from '../../../../reducers/watson/player/profile.types';
import { Address } from '../../../../reducers/watson/player/address.types';
import { Tag } from '../../../../reducers/watson/player/tags.types';

export interface PlayerCardProps {
  profile: Profile;
  address: Address[];
  tags: Tag[];
}

const PlayerCard: React.FC<PlayerCardProps> = props => {
  const [isProfileDialogOpen, setIsProfileDialogOpen] = useState(false);
  const { profile, address, tags } = props;
  const { DisplayName } = profile;

  const playerDp =
    profile.PhotoURL && getPlayerDpUrl(profile.PhotoURL as string);

  return (
    <div className={styles['player-card']}>
      <div className={styles['player-dp']}>
        {playerDp ? (
          <img
            src={playerDp}
            alt="player dp"
            className={styles['player-dp-img']}
          />
        ) : (
          <Icon icon="user" iconSize={100} />
        )}
      </div>
      <div className={styles['player-details']}>
        <div>
          {/* <img src={verifiedBadge} alt="verified" /> */}
          <span className={styles.name}>{DisplayName}</span>
        </div>
        <div className={styles['player-id']}>
          {/* Last played on DD/MM/YYYY at HH:MM */}
          {profile.PlayerId}
        </div>
        <div>
          <div
            className={styles['profile-details-btn']}
            role="button"
            tabIndex={0}
            onClick={(): void => setIsProfileDialogOpen(true)}
            onKeyDown={(): void => setIsProfileDialogOpen(true)}
          >
            View More Details
          </div>
        </div>
        <ProfileDialog
          isOpen={isProfileDialogOpen}
          onClose={setIsProfileDialogOpen}
          profile={profile}
          address={address}
          tags={tags}
        />
      </div>
    </div>
  );
};

export default React.memo(PlayerCard);
