import React from 'react';
import { Switch } from 'react-router-dom';
import { History } from 'history';
import ReactRouterPropTypes from 'react-router-prop-types';

import styles from './Dashboard.module.scss';

import DashboardChooser from './DashboardChooser';
import MegaNavbar from '../MegaNavbar/MegaNavbar';
import Watson, {
  navbarMenuItems as watsonNavbarMenuItems
} from '../../watson/Watson';
import ProtectedRoute from '../ProtectedRoute/ProtectedRoute';

type DashboardProps = {
  history: History;
};

const Dashboard: React.FC<DashboardProps> = props => {
  const { history } = props;
  const isWatsonSelected = history.location.pathname.startsWith('/watson');

  return (
    <div className={styles.dashboard}>
      <div className={styles['mega-navbar']}>
        <MegaNavbar menuItems={isWatsonSelected ? watsonNavbarMenuItems : []} />
      </div>
      <div className={styles.content}>
        <Switch>
          <ProtectedRoute exact path="/" component={DashboardChooser} />
          <ProtectedRoute path="/watson" component={Watson} />
        </Switch>
      </div>
    </div>
  );
};

Dashboard.propTypes = {
  history: ReactRouterPropTypes.history.isRequired
};

export default Dashboard;
