import React from 'react';

import styles from './PlayerKyc.module.scss';
import { DetailsByOcr } from '../../../../reducers/watson/player/kycData.types';

interface DetailsByOCRProps {
  data: DetailsByOcr;
}

const DetailsByOCR: React.FC<DetailsByOCRProps> = props => {
  const { data } = props;

  return (
    <div id={styles['details-by-ocr']}>
      <div className={styles.text}>DETAILS BY OCR</div>
      <div className={styles.details}>
        {Object.keys(data).map(key => {
          return (
            <div className={styles.detail} key={key}>
              <span className={styles.label}>{key}</span>
              <span className={styles.value}>{data[key]}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default DetailsByOCR;
