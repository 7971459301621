import React from 'react';
import { MenuItem, Button } from '@blueprintjs/core';
import { Select, ItemRenderer } from '@blueprintjs/select';
import moment from 'moment';
import { useDispatch } from 'react-redux';

import { KycOrder, AtedAt } from '../../../../reducers/watson/kyc/types';
import { getOtherDocumentData } from '../../../../reducers/watson/player/actions';

export interface KycOrdersListProps {
  kycOrders: KycOrder[];
  selectedOrder: KycOrder | undefined;
  setSelectedOrder(so: KycOrder): void;
  authToken: string;
}

const KycOrderSelect = Select.ofType<KycOrder>();

const KycOrdersList: React.FC<KycOrdersListProps> = props => {
  const { kycOrders, selectedOrder, setSelectedOrder, authToken } = props;
  const dispatch = useDispatch();

  const formatDate = (date: AtedAt): string =>
    moment(date.seconds * 1000).format('lll');

  const renderKycOrder: ItemRenderer<KycOrder> = (
    kycOrder,
    { handleClick, modifiers }
  ) => {
    if (!modifiers.matchesPredicate) {
      return null;
    }

    return (
      <MenuItem
        active={kycOrder === selectedOrder}
        disabled={modifiers.disabled}
        key={kycOrder.id}
        // label={kycOrder.Product.KycDocumentType}
        text={`${formatDate(kycOrder.CreatedAt)} - ${kycOrder.id} - ${
          kycOrder.Product.KycDocumentType
        }`}
        onClick={handleClick}
      />
    );
  };

  return (
    <div
      className="kyc-orders-list"
      style={{ textAlign: 'center', marginTop: '24px' }}
    >
      <KycOrderSelect
        items={kycOrders}
        itemRenderer={renderKycOrder}
        onItemSelect={(kycOrder): void => {
          dispatch(
            getOtherDocumentData({
              orderId: kycOrder.id,
              authToken,
              playerId: kycOrder.PlayerId
            })
          );
          setSelectedOrder(kycOrder);
        }}
        filterable={false}
      >
        <Button
          text={
            selectedOrder &&
            `${formatDate(selectedOrder.CreatedAt)} - ${selectedOrder.id} - ${
              selectedOrder.Product.KycDocumentType
            }`
          }
          rightIcon="double-caret-vertical"
        />
      </KycOrderSelect>
    </div>
  );
};

export default KycOrdersList;
