import React, { useState } from 'react';
import Viewer from 'react-viewer';

import styles from './PlayerKyc.module.scss';
import { FileUrls } from '../../../../reducers/watson/player/kycData.types';

interface DocumentImagesProps {
  data: FileUrls;
}

const DocumentImages: React.FC<DocumentImagesProps> = props => {
  const { data } = props;
  const images = [
    {
      src: data.front || '',
      alt: 'Front'
    }
  ];
  if (data.back) {
    images.push({
      src: data.back || '',
      alt: 'Back'
    });
  }

  const [isVisible, setIsVisible] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  const openViewer = (ai: number): void => {
    setActiveIndex(ai);
    setIsVisible(true);
  };

  return (
    <div id={styles['document-images']}>
      <div
        className={styles['document-image-div']}
        role="button"
        tabIndex={0}
        onClick={(): void => openViewer(0)}
        onKeyDown={(e): void => {
          if (e.key === 'Enter') {
            openViewer(0);
          }
        }}
      >
        Front
        <img
          src={data.front}
          alt="Front document"
          className={styles['document-image']}
        />
      </div>

      {data.back && (
        <div
          className={styles['document-image-div']}
          role="button"
          tabIndex={0}
          onClick={(): void => openViewer(1)}
          onKeyDown={(e): void => {
            if (e.key === 'Enter') {
              openViewer(1);
            }
          }}
        >
          Back
          <img
            src={data.back}
            alt="Front document"
            className={styles['document-image']}
          />
        </div>
      )}

      <Viewer
        visible={isVisible}
        activeIndex={activeIndex}
        rotatable
        zoomable
        images={images}
        onClose={(): void => setIsVisible(false)}
        minScale={1}
      />
    </div>
  );
};

export default DocumentImages;
